import React from "react";

const Team = ({
  results,
  homeTeamShirt,
  awayTeamShirt,
  homeTeamName,
  awayTeamName,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className={`team-container ${homeTeamName && `team-container-home`}`}
    >
      <img
        style={{ width: "3.5rem" }}
        className="team-img"
        src={`${homeTeamShirt || awayTeamShirt}?not-from-cache-please`}
        crossOrigin={"anonymous"}
        alt="shirt"
      />
      <div className="team-name-container text-center d-flex">
        <div className="team-name  mb-0">{homeTeamName || awayTeamName}</div>
      </div>
    </div>
  );
};

export default React.memo(Team);
