import { shareUrlWithFriends } from "../../../environment";
import { platformShareHandler } from "../PlatfromsUrls";
import "./SocialIcons.css";

//The SocialIcons component takes a url for sharing and a platfroms arr
//Return a share button for each platform

const SocialIcons = ({ linkUrl, imgUrl, platforms, isMobile }) => {
  return (
    <div
      className={
        isMobile ? "mobileContainer__SocialIcons" : "container__SocialIcons"
      }>
      {platforms.map((platform, index) => (
        <div key={index}>
          <div
            className='iconWraper__SocialIcons'
            onClick={() => {
              linkUrl
                ? platformShareHandler(platform.tag, linkUrl)
                : platformShareHandler(platform.tag, shareUrlWithFriends);
            }}>
            <img
              className='socialIcon__SocialIcons'
              src={platform.icon}
              alt={platform.name}
            />
          </div>
          <p className='platform-name__SocialIcons'>{platform.name}</p>
        </div>
      ))}
    </div>
  );
};

export default SocialIcons;
