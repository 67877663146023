import React, { useContext, useState, createRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import { useLocation, Link } from "react-router-dom";
import arrowBack from "../images/results/arrowBack.png";
import moment from "moment";
import Team from "../Matches/Team";
import ScoreBox from "./ScoreBox";

import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "../leagues/leagues";
import MatchSuccessPoints from "./../Matches/MatchSuccessPoints";
import RoundSummary from "./../Matches/roundSummary";
import MatchesSuccessIndicators from "./../GeneralComponents/MatchesSuccessIndicators";
import { PredictionsContext } from "./../contexts/PredictionsContext";
import { hasMatchEndedYet } from "./../helpers/match-status";
import { useMediaQuery } from "react-responsive";
import CostumButton from "../GeneralComponents/Button";
import { sharePredictionsHandler } from "../helpers/share-predictions";
import PagesEnum from "../enums/pages";
import Share from "../components/Share/Share";
import { Platforms } from "../components/Share/PlatfromsUrls";

const roundStyle = {
  fontWeight: 500,
  paddingTop: "1em",
  marginBottom: 3,
  color: "#979797",
};
const roundDateStyle = {
  fontWeight: 500,
  fontSize: 16,
  paddingBottom: "1em",
};

const roundDateBoxStyle = { boxShadow: "0px 1px 6px rgba(150, 150, 150, 0.3)" };

const arrowBackStyle = {
  position: "absolute",
  marginLeft: "1em",
  marginTop: 25,
};

const ResultDetails = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const param = useParams();
  const history = useHistory();

  const { englishPastRoundsPredictions, spanishPastRoundPredictions } =
    useContext(ResultsScheduleContext);

  const { englishCurrentRoundAndSubmission, spanishCurrentRoundAndSubmission } =
    useContext(PredictionsContext);

  const location = useLocation();

  //screenshot states
  const [image, setImage] = useState(null);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [blob, setBlob] = useState({});
  const ref = createRef(null);
  const [shareLoading, setShareLoading] = useState(false);

  const getCurrentLeagueRound = () => {
    if (
      location.pathname ===
      `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}/${param.roundId}`
    ) {
      const keyOfSpanishRound = spanishPastRoundPredictions?.findIndex(
        (round) => {
          return round.round.id === +param.roundId;
        }
      );
      if (keyOfSpanishRound > -1) {
        return spanishPastRoundPredictions[keyOfSpanishRound];
      } else if (
        +param.roundId === spanishCurrentRoundAndSubmission?.round?.id
      ) {
        return spanishCurrentRoundAndSubmission;
      }
    } else if (
      location.pathname ===
      `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}/${param.roundId}`
    ) {
      const keyOfEnglishRound = englishPastRoundsPredictions?.findIndex(
        (round) => {
          return round.round.id === +param.roundId;
        }
      );
      if (keyOfEnglishRound > -1) {
        return (
          englishPastRoundsPredictions &&
          englishPastRoundsPredictions[keyOfEnglishRound]
        );
      } else if (
        +param.roundId === englishCurrentRoundAndSubmission?.round?.id
      ) {
        return englishCurrentRoundAndSubmission;
      }
    }
  };

  const roundId = getCurrentLeagueRound()?.round?.id;

  const submissionResults =
    getCurrentLeagueRound()?.submission?.matchPredictions;
  const checkedPredictions = submissionResults?.reduce(
    (acc, curr) => acc + (curr.status !== "pending" ? 1 : 0),
    0
  );

  const getMatches = getCurrentLeagueRound()?.round?.matches;
  const getRound = getCurrentLeagueRound()?.round?.name;
  const getRoundDate = getCurrentLeagueRound()?.round?.matches[0]?.match_start;

  const isAndroid = sessionStorage.getItem("os") === "android";

  //this function invokes from the native to let us know if the postMessage was successfull
  window.receivedPostMsg = () => {
    setShareLoading(false);
  };

  return (
    <div>
      <Link
        to={`/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}`}
        style={arrowBackStyle}
      >
        <img src={arrowBack} alt="arrow" />
      </Link>
      <div ref={ref}>
        <div style={roundDateBoxStyle}>
          <p className="text-center" style={roundStyle}>
            JORNADA {getRound}
          </p>
          <p className="round text-center" style={roundDateStyle}>
            {moment(getRoundDate).format("ddd, MMMM D")}
          </p>
        </div>
        <div className="border-bottom">
          {checkedPredictions ? (
            <MatchesSuccessIndicators
              submissionResults={submissionResults}
              matches={getMatches}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="container-fluid" style={{ backgroundColor: "#FAFAFA" }}>
          <div
            style={
              isDesktopOrLaptop
                ? {
                    marginBottom: "6em",
                    background: "#FAFAFA",
                    paddingTop: "1em",
                  }
                : {
                    marginBottom: "6em",
                    background: "#FAFAFA",
                    paddingTop: "1em",
                  }
            }
            className="container-md"
          >
            <div>
              {getMatches?.map((match) => {
                const Prediction = submissionResults.find(
                  (result) => result.matchId === match.id
                );
                return (
                  <div
                    key={match.id.toString()}
                    className="mb-2 match-box col-md-5 ml-md-5 border justify-content-between overflow-hidden"
                    style={{ display: "inline-flex" }}
                  >
                    {(Prediction?.successPoints && (
                      <MatchSuccessPoints points={Prediction?.successPoints} />
                    )) ||
                      null}

                    <Team
                      results={true}
                      homeTeamName={match.homeTeam.name}
                      homeTeamShirt={match.homeTeam.shirt_img}
                    />
                    <ScoreBox
                      userHomePrediction={Prediction?.home_score}
                      userAwayPrediction={Prediction?.away_score}
                      realResultHomeTeam={match.matchScore?.home_score}
                      realResultAwayTeam={match.matchScore?.away_score}
                      hasMatchEnded={hasMatchEndedYet(match.status_code)}
                    />
                    <Team
                      results={true}
                      awayTeamName={match.awayTeam.name}
                      awayTeamShirt={match.awayTeam.shirt_img}
                    />
                  </div>
                );
              })}
            </div>
            <CostumButton>
              <button
                onClick={() => {
                  setShareLoading(true);
                  sharePredictionsHandler(
                    ref,
                    setIsDisplayed,
                    PagesEnum.results,
                    setBlob,
                    setImage,
                    setShareLoading,
                    roundId
                  );
                }}
                data-target="#myModal"
                className={`button${shareLoading ? "-disabled" : ""}`}
                style={{
                  // marginBottom: !isAndroid ? "6rem" : "1.5rem",
                  marginTop: "1.5rem",
                }}
              >
                {shareLoading ? "Loading..." : "Compartir mis resultados"}
              </button>
            </CostumButton>
            {isDisplayed && (
              <Share
                blob={blob}
                ready={isDisplayed}
                title="Compartir mis resultados"
                imageUrl={image}
                setReady={setIsDisplayed}
                platforms={Platforms}
              />
            )}
            {checkedPredictions ? (
              <RoundSummary submission={getCurrentLeagueRound()?.submission} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultDetails;
