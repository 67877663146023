import React from "react";

function CostumButton({ children, inTheMiddleOfThePage, className = "" }) {
  if (inTheMiddleOfThePage) {
    return (
      <div data-html2canvas-ignore className={`"button-no-margin-bottom" ${className}`}
      >
        {children}
      </div>
    );
  } else {
    return (
      <div data-html2canvas-ignore className={`"button-wrapper" ${className}`}>
        {children}
      </div>
    );
  }
}

export default CostumButton;
