import React, { useEffect, useState } from "react"
import smallShirt from "../images/accumulator/accumulatorShirt.svg"
import viCircle from "../images/accumulator/viCircle.svg"
import vi from "../images/accumulator/vi.svg"

function AccumulatorSingleMatchOU({
  markets,
  homeTeamName,
  awayTeamName,
  handleOU,
  index,
  homeTeamShirt,
  awayTeamShirt,
  updatedFtrArr,
}) {
  //navigating inside the API

  let market = markets.findIndex((market) => {
    return market.marketType === 2
  })

  let possibleOutcomes = markets[market]?.possibleOutcomes

  //where to put v initially
  let outcomeTrue = possibleOutcomes?.find((outcome) => {
    if (updatedFtrArr) {
      return outcome.id === updatedFtrArr
    } else {
      return outcome.isSelected === true
    }
  })

  let outcomeNameTrue = outcomeTrue?.outcomeName

  //

  let overOddsIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome?.outcomeName === "Over2_5"
  })

  let underOddsIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome?.outcomeName === "Under2_5"
  })

  const overOdds = markets[market]?.possibleOutcomes[overOddsIndex]?.odds
  const overId = markets[market]?.possibleOutcomes[overOddsIndex]?.id

  const underOdds = markets[market]?.possibleOutcomes[underOddsIndex]?.odds
  const underId = markets[market]?.possibleOutcomes[underOddsIndex]?.id
  //end of navigation inside the API

  //converting to american odds
  const oddsAmericanConverter = (decimalNum) => {
    if (decimalNum > 2) {
      return (decimalNum = `+${Math.round((decimalNum - 1) * 100)}`)
    } else if (decimalNum < 2) {
      return (decimalNum = Math.round(-100 / (decimalNum - 1)))
    }
  }

  let americanOver = markets[market]?.possibleOutcomes[overOddsIndex].usPrice
  let americanUnder = markets[market]?.possibleOutcomes[underOddsIndex].usPrice

  //btn false/true
  const [underBtn, setUnderBtn] = useState(false)
  const [overBtn, setOverBtn] = useState(false)

  //initialize page v's
  useEffect(() => {
    if (outcomeNameTrue === "Over2_5") {
      setOverBtn(true)
      handleOU(index, overId, !overBtn)
    } else if (outcomeNameTrue === "Under2_5") {
      setUnderBtn(true)
      handleOU(index, underId, !underBtn)
    }
  }, [])

  function underBtnToggle() {
    if (!underBtn){
      setUnderBtn(true)
      setOverBtn(false)
      handleOU(index, underId, true)
    } else {
      setUnderBtn(false)
      handleOU(index, underId, false)
    }

  }
  function overBtnToggle() {
    if (!overBtn){
      setOverBtn(true)
      setUnderBtn(false)
      handleOU(index, overId, true)
    } else {
      setOverBtn(false)
      handleOU(index, overId, false)
    }

  }
  // END btn false/true

  return (
    <div className="container">
      <div className="row">
        <div className="col-5 d-flex px-0 pt-2">
          <img className="small-shirt" src={homeTeamShirt} alt="small shirt" />
          <p className="mb-2  pl-2">{homeTeamName}</p>
        </div>

        <button onClick={overBtnToggle} className="col text-center btn-style">
          {overBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanOver > 0 && '+'}{americanOver}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
        <button onClick={underBtnToggle} className="col text-center btn-style">
          {underBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanUnder > 0 && '+'}{americanUnder}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  style={{ display: "none" }}
                  alt=""
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
      </div>
      <div className="row border-bottom">
        <div className="col-5 d-flex px-0 pb-2">
          <img className="small-shirt" src={awayTeamShirt} alt="small shirt" />
          <p className="m-0 px-2">{awayTeamName}</p>
        </div>
      </div>
    </div>
  )
}

export default AccumulatorSingleMatchOU
