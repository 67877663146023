import LeaderboardTabs from "./../ResultsSchedule/leaderboardTabs";
import leaderboardHeaderImg from "../images/leaderboard/leaderboardHeader.png";
import backImg from "../images/leaderboard/back.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./leaderboard.css";
import { useContext, useEffect, useState } from "react";
import ModalPicker from "../Modal/ModalPicker";
import { useCallback } from "react";
import { useRef } from "react";
import medal1 from "../images/leaderboard/twemoji_1st-place-medal.png";
import medal2 from "../images/leaderboard/twemoji_2nd-place-medal.png";
import medal3 from "../images/leaderboard/twemoji_3rd-place-medal.png";
import rankUp from "../images/leaderboard/rank_up.png";
import rankDown from "../images/leaderboard/rank_down.png";
import { BASE_URL } from "../environment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Header from "../Header/Header";
import NicknameModal from "../Modal/NicknameModal";
import { UserContext } from "./../contexts/UserContext";
import MediaQuery from "react-responsive";
import { CURRENT_CAMPAIGN_ID, CURRENT_LEAGUE1 } from "../leagues/leagues";
import TermsFooter from "../GeneralComponents/TermsFooter";
import { PredictionsContext } from "../contexts/PredictionsContext";

const pickers = [
  { displayName: "Jornada", name: "Round", hasPicker: true },
  { displayName: "Mes", name: "Month", hasPicker: true },
  { displayName: "Temporada", name: "Season", hasPicker: false },
];

const getMedalImg = (rank) => {
  switch (rank) {
    case 1:
      return medal1;
    case 2:
      return medal2;
    case 3:
      return medal3;
    default:
      return null;
  }
};

function Leaderboard() {
  const [selectedPicker, setSelectedPicker] = useState("Season");
  const [modalOptions, setModalOptions] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [months, setMonths] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [selfRanking, setSelfRanking] = useState(null);
  const [periodDisplayName, setPeriodDisplayName] = useState("Temporada");
  const [nicknameModalOpen, setNicknameModalOpen] = useState(false);
  const location = useLocation();
  const { hasNickname, user, askUserForNicknameAttemptsLeft } =
    useContext(UserContext);

  const pickerRef = useRef(null); //the current opened picker

  const { setLeaderboardLoading, leaderboardLoading } =
    useContext(PredictionsContext);

  const onModalPick = useCallback((selectedOption) => {
    setSelectedPeriod(selectedOption.value);
    setPeriodDisplayName(selectedOption.displayValue);
    setSelectedPicker(pickerRef.current.name);
  }, []);

  const handlePickerChange = (pickerName) => {
    switch (pickerName) {
      case "Month":
        setModalOptions(
          months?.map((m, index) => ({
            displayValue: moment(m).format("MMMM"),
            value: m,
            key: index,
          }))
        );
        break;
      case "Round":
        setModalOptions(
          rounds?.map((r, index) => ({
            displayValue: `Jornada ${r.name} - ${moment(r.roundStart).format(
              "DD.MM.YYYY"
            )}`,
            value: r.number,
            key: index,
          }))
        );
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!hasNickname && user) {
      if (askUserForNicknameAttemptsLeft.current > 0) {
        askUserForNicknameAttemptsLeft.current--;
        setNicknameModalOpen(true);
      }
    }
  }, [hasNickname, user]);

  useEffect(() => {
    if (nicknameModalOpen) return;
    setLeaderboardLoading(true);
    const leagueName =
      location?.pathname && location.pathname.substring(1).split("/")[1];
    const campaignId = CURRENT_CAMPAIGN_ID;
    let jwt = localStorage.getItem("jwt-token");
    axios
      .get(`${BASE_URL}/api/get-leaderboard`, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
        params: {
          campaignId,
          period: selectedPeriod,
          type: selectedPicker?.toUpperCase(),
        },
      })
      .then((res) => {
        setUsersCount(res?.data?.numberOfUsersInLeaderboard);
        setLeaderboardData(res?.data?.leaderboard);
        setSelfRanking(res?.data?.userLeaderboardRanking);
        setLeaderboardLoading(false);
      });
  }, [selectedPeriod, setLeaderboardLoading, location, nicknameModalOpen]);

  useEffect(() => {
    setLeaderboardLoading(true);
    const leagueName =
      location?.pathname && location.pathname.substring(1).split("/")[1];
    const campaignId = CURRENT_CAMPAIGN_ID;
    let jwt = localStorage.getItem("jwt-token");
    axios
      .get(`${BASE_URL}/api/get-league-periods`, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
        params: {
          campaignId,
        },
      })
      .then((res) => {
        setMonths(res.data?.months);
        setRounds(res.data?.rounds);
        setSelectedPeriod("SEASON");
        setPeriodDisplayName("Temporada");
        setLeaderboardLoading(false);
      });
  }, [setMonths, setRounds, setLeaderboardLoading, location]);

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <div
        style={{
          paddingTop: `env(safe-area-inset-top, 50px)`,
          backgroundColor: "white",
          display: "block",
        }}
        className="medium-shadow"
      >
        <div>
          <p
            style={{
              textAlign: "center",
              paddingTop: "2em",
              fontWeight: "bold",
            }}
          >
            CLASIFICACIÓN
          </p>
        </div>
        <Header leaderboard={true} />
      </div>
      {leaderboardLoading ? (
        <div>
          <Spinner
            className="spinner"
            animation="border"
            style={{ color: "rgba(121, 192, 0, 1)" }}
          />
        </div>
      ) : (
        <div
          style={{
            paddingTop: `env(safe-area-inset-top, 300px)`,
            marginTop: "3em",
          }}
        >
          <div className="leaderboard-container">
            <div className="medium-shadow leaderboard-period-picker">
              {pickers.map((picker) => (
                <button
                  key={picker.name}
                  onClick={() => {
                    if (picker.hasPicker) {
                      handlePickerChange(picker.name);
                      pickerRef.current = picker;
                    } else {
                      setSelectedPicker(picker.name);
                      setSelectedPeriod(picker.name);
                      setPeriodDisplayName(picker.displayName);
                    }
                  }}
                  className={`leaderboard-period-picker_btn ${
                    selectedPicker === picker.name ? "selected" : ""
                  }`}
                >
                  <span>{picker.displayName}</span>
                  {picker.hasPicker && (
                    <div
                      style={{
                        width: 0,
                        overflow: "visible",
                        display: "inline-block",
                      }}
                    >
                      <ArrowDropDownIcon />
                    </div>
                  )}
                </button>
              ))}
            </div>
            {selfRanking && (
              <>
                <div className="leaderboard-subtitle">Mi clasificación</div>
                <div className="leaderboard-my-rank leaderboard-table medium-shadow">
                  <div className="flex-1 text-left">
                    <div
                      className="text-center"
                      style={{ display: "inline-block", width: "3rem" }}
                    >
                      {selfRanking?.ranking}
                    </div>
                  </div>
                  <div className="flex-1 text-center">{`${selfRanking?.User?.nickname}`}</div>
                  <div className="flex-1 text-right">
                    <>{selfRanking?.points}</>
                    <img
                      style={{ width: "1rem", marginLeft: ".5rem" }}
                      alt=""
                      src={(() => {
                        switch (selfRanking?.upOrDown) {
                          case "up":
                            return rankUp;
                          case "down":
                            return rankDown;
                          case "new":
                          default:
                            return null;
                        }
                      })()}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="leaderboard-subtitle d-flex flex-row align-items-center">
              <div
                className="leaderboard-grey-text flex-1 text-right"
                style={{ fontSize: ".8rem" }}
              >
                {usersCount} Miembros
              </div>
              <div className="flex-2">{periodDisplayName}</div>
              <div className="flex-1"></div>
            </div>
            <div className="medium-shadow leaderboard-table leaderboard-table-main">
              <div className="leaderboard-grey-text d-flex flex-row align-items-center pb-3">
                <div className="flex-1">Clasificación</div>
                <div className="flex-1" style={{ textAlign: "center" }}>
                  Usuario
                </div>
                <div className="flex-1" style={{ textAlign: "right" }}>
                  Puntos
                </div>
              </div>
              {leaderboardData?.map((val, index) => (
                <div
                key={val?.UserRankings[0]?.id}
                className="leaderboard-table-main_row d-flex flex-row align-items-center"
                >
                  <div className="flex-1 d-flex flex-row justify-content-between">
                    <div className="leaderboard-table-row-rank">
                      {val?.UserRankings[0].ranking}
                    </div>
                    {val?.UserRankings[0].ranking <= 3 && (
                      <div style={{ paddingRight: ".8rem" }}>
                        <img src={getMedalImg(val?.UserRankings[0].ranking)} alt="medal"/>
                      </div>
                    )}
                  </div>
                  <div className="flex-1 text-center">{`${
                    val?.nickname ?? "no nickname"
                  }`}</div>
                  <div className="flex-1 text-right">
                    <span>{val?.UserRankings[0].points}</span>
                    <span>
                      <img
                        style={{ width: "1rem", marginLeft: ".5rem" }}
                        alt=""
                        src={(() => {
                          switch (val?.UserRankings[0].upOrDown) {
                            case "up":
                              return rankUp;
                            case "down":
                              return rankDown;
                            case "new":
                            default:
                              return null;
                          }
                        })()}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <ModalPicker
        options={modalOptions}
        selectedOption={selectedPeriod}
        onCloseModal={() => {
          setModalOptions(null);
          pickerRef.current = null;
        }}
        onSelectOption={onModalPick}
      />
      <NicknameModal
        onCloseModal={(selectedNickname) => {
          setNicknameModalOpen(false);
        }}
        isOpen={nicknameModalOpen}
      />
    </div>
  );
}

export default Leaderboard;
