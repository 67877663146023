import React from "react";
import { Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Banner = ({ banner, accumulatorHeader = false, redirectURL }) => {
  const redirectBanner = () => {
    window.open(redirectURL, "_blank");
  };

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 600px)",
  });

  if (!banner) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          // className="spinner"
          size={"S"}
          animation="border"
          style={{
            color: "rgb(121, 192, 0)",
            padding: "50px",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        marginLeft: accumulatorHeader && !isMobileScreen ? "110px" : "0px",
      }}
    >
      <img
        className="d-block my-2 banner"
        src={banner}
        alt="banner"
        onClick={redirectBanner}
        style={{
          cursor: "pointer",
          height: !isMobileScreen ? "171px" : "82px",
        }}
      />
    </div>
  );
};

export default Banner;
