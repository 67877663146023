import React, { useContext, useEffect, useState } from "react";
import smallShirt from "../images/accumulator/accumulatorShirt.svg";
import viCircle from "../images/accumulator/viCircle.svg";
import vi from "../images/accumulator/vi.svg";

function AccumulatorSingleMatchFTResult({
  homeTeamName,
  awayTeamName,
  markets,
  handleFt,
  index,
  homeTeamShirt,
  awayTeamShirt,
  updatedFtrArr,
}) {
  //navigating inside the API

  let possibleOutcomes = markets.find((market) => {
    return market.marketType === 1;
  })?.possibleOutcomes;

  //where to put v initially
  let outcomeTrue = possibleOutcomes?.find((outcome) => {
    if (updatedFtrArr) {
      return outcome.id === updatedFtrArr;
    } else {
      return outcome.isSelected === true;
    }
  });

  let outcomeNameTrue = outcomeTrue?.outcomeName;

  //

  let HomeWinOddsIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome.outcomeName === "HomeWin";
  });

  let DrawOddsIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome.outcomeName === "Draw";
  });

  let awayWinOddsIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome.outcomeName === "AwayWin";
  });

  let homeWinOdds = possibleOutcomes[HomeWinOddsIndex]?.odds;
  let homeWinId = possibleOutcomes[HomeWinOddsIndex]?.id;

  let drawOdds = possibleOutcomes[DrawOddsIndex]?.odds;
  let drawId = possibleOutcomes[DrawOddsIndex]?.id;

  let awayWinOdds = possibleOutcomes[awayWinOddsIndex]?.odds;
  let awayWinId = possibleOutcomes[awayWinOddsIndex]?.id;

  //convert from decimal to american odds
  const oddsAmericanConverter = (decimalNum) => {
    if (decimalNum > 2) {
      return (decimalNum = `+${Math.round((decimalNum - 1) * 100)}`);
    } else if (decimalNum < 2) {
      return (decimalNum = Math.round(-100 / (decimalNum - 1)));
    }
  };

  let americanHomeWinOdds = possibleOutcomes[HomeWinOddsIndex]?.usPrice;
  let americanDrawWinOdds = possibleOutcomes[DrawOddsIndex]?.usPrice;
  let americanAwayWinOdds = possibleOutcomes[awayWinOddsIndex]?.usPrice;
  //end of navigation inside API

  //turning selection btn on and sending the info to parent callback
  const [homeBtn, setHomeBtn] = useState(false);
  const [drawBtn, setDrawBtn] = useState(false);
  const [awayBtn, setAwayBtn] = useState(false);

  //initialize page v's
  useEffect(() => {
    if (outcomeNameTrue === "HomeWin") {
      setHomeBtn(true);
      handleFt(index, homeWinId, !homeBtn);
    } else if (outcomeNameTrue === "Draw") {
      setDrawBtn(true);
      handleFt(index, drawId, !drawBtn);
    } else if (outcomeNameTrue === "AwayWin") {
      setAwayBtn(true);
      handleFt(index, awayWinId, !awayBtn);
    }
  }, []);

  function homeBtnToggle() {
    if (!homeBtn) {
      setHomeBtn(true);
      setDrawBtn(false);
      setAwayBtn(false);
      handleFt(index, homeWinId, true);
    } else {
      setHomeBtn(false);
      handleFt(index, homeWinId, false);
    }
  }

  function drawBtnToggle() {
    if (!drawBtn) {
      setDrawBtn(true);
      setHomeBtn(false);
      setAwayBtn(false);
      handleFt(index, drawId, true);
    } else {
      setDrawBtn(false);
      handleFt(index, drawId, false);
    }
  }
  function awayBtnToggle() {
    if (!awayBtn) {
      setAwayBtn(true);
      setHomeBtn(false);
      setDrawBtn(false);
      handleFt(index, awayWinId, true);
    } else {
      setAwayBtn(false);
      handleFt(index, awayWinId, false);
    }
  }

  //end of turning btn on/off

  return (
    <div className="container">
      <div className="row">
        <div className="col-5 d-flex px-0 pt-2">
          <img className="small-shirt" src={homeTeamShirt} alt="small shirt" />
          <p className="mb-2  pl-2">{homeTeamName}</p>
        </div>
        <button onClick={homeBtnToggle} className="col text-center btn-style">
          {homeBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanHomeWinOdds > 0 && "+"}
              {americanHomeWinOdds}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
        <button
          onClick={drawBtnToggle}
          className="col p-0 text-center btn-style"
        >
          {drawBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanDrawWinOdds > 0 && "+"}
              {americanDrawWinOdds}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
        <button onClick={awayBtnToggle} className="col text-center btn-style">
          {awayBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanAwayWinOdds > 0 && "+"}
              {americanAwayWinOdds}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
      </div>
      <div className="row border-bottom">
        <div className="col-5 d-flex px-0 pb-2">
          <img className="small-shirt" src={awayTeamShirt} alt="small shirt" />
          <p className="m-0 px-2">{awayTeamName}</p>
        </div>
      </div>
    </div>
  );
}

export default AccumulatorSingleMatchFTResult;
