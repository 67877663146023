import React, { useState } from "react";
import "./Welcome.css";
import mobileWelcomeWall from "../../images/welcome/NewMobileWelcomeBg.png";
import desktopWellcomeWall from "../../images/welcome/desktop-welcome-1920x1080px.png";
import welcomeItemLogo1 from "../../images/welcome/welcomeItemOne.svg";
import welcomeItemLogo2 from "../../images/welcome/welcomeItemTwo.svg";
import welcomeItemLogo3 from "../../images/welcome/welcomeItemThree.svg";
import MVPLogo from "../../images/welcome/MVP_codere_Box.svg";
import DesktopTopLines from "../../images/welcome/DesktopTopLines.svg";
import MobileTopLines from "../../images/welcome/MobileTopLines.svg";
import MobileBottomLines from "../../images/welcome/WelcomeBottomLines.svg";
import DesktopBottomLines from "../../images/welcome/lines.png";
import codere from "../../images/welcome/CodereLogo.svg";
import { Link } from "react-router-dom";
import { CURRENT_LEAGUE_ENDPOINT_1 } from "../../leagues/leagues";
import { useMediaQuery } from "react-responsive";
import WelcomeItem from "../../components/Welcome";
import Header from "../../Header/Header";

const Welcome = () => {
  const [showModal, setShowModal] = useState(false);

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 600px)",
  });

  return (
    <div
      style={{
        backgroundImage: `url(${
          isMobileScreen ? mobileWelcomeWall : desktopWellcomeWall
        })`,
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // overflowY: "hidden",
      }}
    >
      <div className="container__welcome">
        {!isMobileScreen && (
          <Header bgColor={"#334049"} textColor={"#79C000"} />
        )}
        <div
          className="content__welcome"
          style={{
            height: "100%",
            // width: isMobileScreen ? "100%" : "75%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div style={{ flex: isMobileScreen ? 0.55 : 0.25 }}>
            <div className="TopContentContainer__welcome">
              <div className="TopLines__welcome">
                <img
                  src={isMobileScreen ? MobileTopLines : DesktopTopLines}
                  alt="TopLines"
                />
              </div>
              <div
                className="above-logo-text__welcome"
                style={{ maxWidth: isMobileScreen ? "100vw" : "60vw" }}
              >
                LLENA TU QUINIELA
              </div>
              <div className="mvp-logo__welcome">
                <img src={MVPLogo} alt="MVPLogo" />
              </div>
              <div
                className="below-logo-text__welcome"
                style={{ maxWidth: isMobileScreen ? "100vw" : "60vw" }}
              >
                Y PARTICIPA POR <br />
              </div>
              <div className="inside-below-logo-text__welcome">
                ¡GRANDES PREMIOS!
              </div>
            </div>
          </div>
          <div
            style={{ display: isMobileScreen ? "block" : "flex", flex: 0.25 }}
          >
            <div className="welcome-items__welcome">
              <WelcomeItem
                index={1}
                icon={welcomeItemLogo1}
                title="HAZ TU PRONÓSTICOS"
                text="Coloca los resultados que crees que ocurrirán"
              />
              <WelcomeItem
                index={2}
                icon={welcomeItemLogo2}
                title="ACIERTA"
                text="La mayor cantidad de marcadores"
              />
              <WelcomeItem
                index={3}
                icon={welcomeItemLogo3}
                title="GANA"
                text="Experiencias VIP, Xbox, jerseys o freebets"
              />
            </div>
          </div>
          <div
            style={{ flex: isMobileScreen ? 0 : 0.45 }}
            className="welcomeBtn__welcome"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                to={`/${CURRENT_LEAGUE_ENDPOINT_1}`}
                className="button__welcome"
              >
                JUGAR GRATIS
              </Link>
            </div>
          </div>
          <div className="bottomLogo__welcome">
            <img
              src={codere}
              alt="codere logo"
              style={{ width: isMobileScreen ? "170px" : "260px" }}
            />
            <img
              src={isMobileScreen ? MobileBottomLines : DesktopBottomLines}
              alt="bottom lines logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
