import React, { useContext } from "react"
import { PredictionsContext } from "../contexts/PredictionsContext"
import editIcon from "../images/header/editIcon.svg"
import editIconDisabled from "../images/header/editIconDisabled.svg"
import { useLocation } from "react-router-dom"
import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "../leagues/leagues"

function EditBtn({ userAddedPredictions, editMode, setEditMode }) {
  const { spanish_RoundNumber_CurrentRound, english_RoundNumber_CurrentRound } =
    useContext(PredictionsContext)

  const location = useLocation()

  const hasRoundStarted = !!(
    (location.pathname === `/${CURRENT_LEAGUE_ENDPOINT_1}` &&
      spanish_RoundNumber_CurrentRound) ||
    (location.pathname === `/${CURRENT_LEAGUE_ENDPOINT_2}` &&
      english_RoundNumber_CurrentRound)
  )

  return (
    <div data-html2canvas-ignore>
      {userAddedPredictions && !hasRoundStarted && (
        <>
          <input
            disabled={editMode}
            checked={editMode}
            onChange={() => {
              setEditMode(!editMode);
            }}
            className="editCheckbox"
            hidden
            type="checkbox"
            id="edit"
            name="edit"
          />
          <label htmlFor="edit" className="editButton">
            <img src={editMode ? editIconDisabled : editIcon} alt="edit icon" />
            <span>Editar</span>
          </label>
        </>
      )}
    </div>
  );
}

export default EditBtn
