import "./SideNavDesktop.css";
import React, { useContext, useEffect, useState } from "react";

import trophyWhite from "./images/sideNavDesktop/trophy.png";
import trophyGreen from "./images/sideNavDesktop/trophyGreen.png";
import accumGreen from "./images/sideNavDesktop/accumGreen.png";
import accumWhite from "./images/sideNavDesktop/accumWhite.png";
import predictionsGreen from "./images/sideNavDesktop/predictionsGreen.png";
import predictionsWhite from "./images/sideNavDesktop/predictionsWhite.png";
import leaderBoardWhite from "./images/sideNavDesktop/leaderBoardWhite.png";
import leaderBoardGreen from "./images/sideNavDesktop/leaderBoardGreen.png";
import MVPCodere from "./images/header/MVPCodereForm.png";
import termsWhite from "./images/sideNavDesktop/Terms-White.png";
import termsGreen from "./images/sideNavDesktop/Terms-Green.png";
import GreenInvite from "./images/sideNavDesktop/GreenInvite.svg";
import WhiteInvite from "./images/sideNavDesktop/WhiteInvite.svg";

import { Link, useHistory, useLocation } from "react-router-dom";
import { ResultsScheduleContext } from "./contexts/ResultsScheduleContext";

import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "./leagues/leagues";
import { PredictionsContext } from "./contexts/PredictionsContext";
import ModalExit from "./Modal/ModalExit";
import Share from "./components/Share/Share";
import { shareUrlWithFriends } from "./environment";
import PagesEnum from "./enums/pages";

const activeTabStyle = {
  backgroundColor: "#BCDF80",
};
const nonActiveTabStyle = {
  backgroundColor: "#353d49",
  margin: "0 auto",
};

const footerStyle = {
  fontSize: 10,
  fontWeight: 500,
  color: "white",
  cursor: "pointer",
};

const footerStyleActive = {
  ...footerStyle,
  color: "#79C000",
};

const linkStyle = {
  textDecoration: "none",
  color: "rgba(53, 61, 73, 1)",
  width: "100%",
  height: "100%",
  display: "block",
  margin: "0 auto",
  cursor: "pointer",
};

const SideNavDesktop = ({ platforms }) => {
  const { isModify, setModify } = useContext(PredictionsContext);

  const [show, setShow] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [moveTo, setMoveTo] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const { nextEnglishRoundSubmission, nextSpanishRoundSubmission } = useContext(
    ResultsScheduleContext
  );

  const [league, setLeague] = useState(CURRENT_LEAGUE_ENDPOINT_1);

  useEffect(() => {
    switch (location.pathname.split("/").filter((str) => str.length)[0]) {
      case CURRENT_LEAGUE_ENDPOINT_1:
        setLeague(CURRENT_LEAGUE_ENDPOINT_1);
        break;
      case CURRENT_LEAGUE_ENDPOINT_2:
        setLeague(CURRENT_LEAGUE_ENDPOINT_2);
        break;
      default:
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
      "results-schedule"
    ) {
      setSelectedTab(PagesEnum.results);
    } else if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
      "leaderboard"
    ) {
      setSelectedTab(PagesEnum.leaderboard);
    } else if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
      "terms-conditions"
    ) {
      setSelectedTab(PagesEnum.termsAndCond);
    } else if (
      location.pathname.split("/").filter((str) => str.length)[1] === "1x2"
    ) {
      setSelectedTab(PagesEnum.parlays);
    } else if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
        CURRENT_LEAGUE_ENDPOINT_1 ||
      location.pathname.split("/").filter((str) => str.length)[0] ===
        CURRENT_LEAGUE_ENDPOINT_2
    ) {
      setSelectedTab(PagesEnum.predictions);
    }
  }, [location.pathname]);

  const clickAccumulations = () => {
    if (isModify) {
      setShow(true);
      setMoveTo(`/${league}/1x2`);
    } else {
      setSelectedTab(PagesEnum.parlays);
      history.push(`/${league}/1x2`);
    }
  };
  const clickResult = () => {
    if (isModify) {
      setMoveTo(`/results-schedule/${league}`);
      setShow(true);
    } else {
      setSelectedTab(PagesEnum.results);
      history.push(`/results-schedule/${league}`);
    }
  };
  const clickLeaderboard = () => {
    if (isModify) {
      setMoveTo(`/leaderboard/${league}`);
      setShow(true);
    } else {
      setSelectedTab(PagesEnum.leaderboard);
      history.push(`/leaderboard/${league}`);
    }
  };
  const clickTermConditions = () => {
    if (isModify) {
      setMoveTo(`/terms-conditions`);
      setShow(true);
    } else {
      setSelectedTab(PagesEnum.termsAndCond);
      history.push(`/terms-conditions`);
    }
  };

  const clickPredictions = () => {
    if (isModify) {
      setMoveTo(`/${league}`);
    } else {
      setSelectedTab(PagesEnum.predictions);
      history.push(`/${league}`);
    }
  };

  const clickShare = () => {
    setSelectedTab("share");
    setShowShareModal(true);
  };

  const backHandler = () => {
    setShow(false);
    setModify(false);
    setSelectedTab(moveTo);
    if (moveTo) {
      history.push(moveTo);
    }
    return;
  };

  return (
    <React.Fragment>
      <ModalExit ready={show} setReady={setShow} back={backHandler} />
      <div className="sidenav-desktop-container">
        <div className="desktop-sidenav-logo-container mt-3 mb-5">
          <div className="dektop-sidenav-img" onClick={() => clickResult()}>
            <img
              src={MVPCodere}
              alt={PagesEnum.results}
              style={{ width: 88 }}
            />
          </div>
        </div>
        <div
          style={
            selectedTab === PagesEnum.results ? footerStyleActive : footerStyle
          }
          className={
            selectedTab === PagesEnum.results
              ? `desktop-sidenav-logo-container-active mb-3`
              : `desktop-sidenav-logo-container mb-3`
          }
        >
          <div className="dektop-sidenav-img" onClick={() => clickResult()}>
            <img
              style={{ width: 41 }}
              src={
                selectedTab === PagesEnum.results ? trophyGreen : trophyWhite
              }
              alt={PagesEnum.results}
            />
            <p className="footer-link">RESULTADOS</p>
          </div>
        </div>
        <div onClick={clickPredictions}>
          <div
            style={
              selectedTab === PagesEnum.predictions
                ? footerStyleActive
                : footerStyle
            }
            className={
              selectedTab === PagesEnum.predictions
                ? `desktop-sidenav-logo-container-active mb-3`
                : `desktop-sidenav-logo-container mb-3`
            }
          >
            <div className="dektop-sidenav-img">
              <img
                style={{ width: 41 }}
                src={
                  selectedTab === PagesEnum.predictions
                    ? predictionsGreen
                    : predictionsWhite
                }
                alt="target"
              />
              <p className="footer-link">
                {(nextEnglishRoundSubmission?.submission &&
                  league === CURRENT_LEAGUE_ENDPOINT_2) ||
                (nextSpanishRoundSubmission?.submission &&
                  league === CURRENT_LEAGUE_ENDPOINT_1)
                  ? "EDITAR PRONÓSTICO"
                  : "PRONÓSTICO"}
              </p>
            </div>
          </div>
        </div>
        <div
          style={
            selectedTab === PagesEnum.leaderboard
              ? footerStyleActive
              : footerStyle
          }
          className={
            selectedTab === PagesEnum.leaderboard
              ? `desktop-sidenav-logo-container-active mb-3`
              : `desktop-sidenav-logo-container mb-3`
          }
        >
          <div
            className="dektop-sidenav-img"
            onClick={() => clickLeaderboard()}
          >
            <img
              style={{ width: 41 }}
              src={
                selectedTab === PagesEnum.leaderboard
                  ? leaderBoardGreen
                  : leaderBoardWhite
              }
              alt={PagesEnum.leaderboard}
            />
            <p className="footer-link">CLASIFICACIÓN</p>
          </div>
        </div>
        <div
          style={
            selectedTab === PagesEnum.parlays ? footerStyleActive : footerStyle
          }
          className={
            selectedTab === PagesEnum.parlays
              ? `desktop-sidenav-logo-container-active mb-3`
              : `desktop-sidenav-logo-container mb-3`
          }
        >
          <div
            className="dektop-sidenav-img"
            onClick={() => clickAccumulations()}
          >
            <img
              style={{ width: 41 }}
              src={selectedTab === PagesEnum.parlays ? accumGreen : accumWhite}
              alt="target"
            />
            <p className="footer-link">PARLAYS</p>
          </div>
        </div>
        <div
          style={
            selectedTab === PagesEnum.termsAndCond
              ? footerStyleActive
              : footerStyle
          }
          className={
            selectedTab === PagesEnum.termsAndCond
              ? `desktop-sidenav-logo-container-active mb-3`
              : `desktop-sidenav-logo-container mb-3`
          }
        >
          <div
            className="dektop-sidenav-img"
            onClick={() => clickTermConditions()}
          >
            <img
              alt={PagesEnum.termsAndCond}
              style={{ width: 41 }}
              src={
                selectedTab === PagesEnum.termsAndCond ? termsGreen : termsWhite
              }
            />
            <p className="footer-link">T&C</p>
          </div>
        </div>
        <div
          style={
            selectedTab === "share" && showShareModal
              ? footerStyleActive
              : footerStyle
          }
          className={
            selectedTab === "share" && showShareModal
              ? `desktop-sidenav-logo-container-active mb-3`
              : `desktop-sidenav-logo-container mb-3`
          }
        >
          <div className="dektop-sidenav-img" onClick={clickShare}>
            <img
              style={{ width: "41px", height: "45px" }}
              src={
                selectedTab === "share" && showShareModal
                  ? GreenInvite
                  : WhiteInvite
              }
              alt="share"
            />
            <p className="footer-link">Invita a tu dupla</p>
          </div>
        </div>
      </div>
      <Share
        title={
          <>
            <span style={{ fontWeight: "bold" }}>
              Recomienda MVP y juega con tus amigos.
            </span>
            <br />
            Dile a tu cuate que descargue MVP y rétalo en la quiniela semanal.
          </>
        }
        linkUrl={shareUrlWithFriends}
        platforms={platforms}
        ready={showShareModal}
        setReady={setShowShareModal}
      />
    </React.Fragment>
  );
};

export default SideNavDesktop;
