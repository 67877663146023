import React, { useContext } from "react";
import Matches from "../Matches/Matches";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import moment from "moment";
import { Spinner } from "react-bootstrap";

function SpanishLeague({footerHeight,from, accumPage }) {
  const { spanishPredictionsForNextRound, spanishPredictionsForLastRound } =
    useContext(ResultsScheduleContext);

  const {
    spanish_BetAllowed_NextRound,
    spanish_Matches_NextRound,
    spanish_RoundNumber_NextRound,
    spanish_RoundId_NextRound,
    spanish_Matches_CurrentRound,
    spanish_RoundNumber_CurrentRound,
    spanish_RoundId_CurrentRound,
    spanish_Predictions_CurrentRound,
    spanish_Matches_LastRound,
    spanish_RoundNumber_LastRound,
    spanish_RoundId_LastRound,
    spanish_Predictions_LastRound,
    editModeSpanishLeague,
    setEditModeSpanishLeague,
    spanishEntriesFrom,
    savedPredictions,
  } = useContext(PredictionsContext);

  const isSubmissionAllowed =
    !spanishEntriesFrom ||
    (spanishEntriesFrom && moment(spanishEntriesFrom).isBefore(moment()));

  if (spanish_Matches_CurrentRound?.length) {
    return (
      <div>
        <Matches
          userAddedPredictions={
            spanish_Predictions_CurrentRound?.length
              ? spanish_Predictions_CurrentRound
              : null
          }
          league="la-liga"
          matches={spanish_Matches_CurrentRound}
          round={spanish_RoundNumber_CurrentRound}
          roundId={spanish_RoundId_CurrentRound}
          spanishEntriesFrom={spanishEntriesFrom}
          nextRoundNumber={spanish_RoundNumber_NextRound}
          currentRound
          footerHeight={footerHeight}
        ></Matches>
      </div>
    );
  } else if (spanish_Matches_NextRound?.length) {
    return (
      <div>
        <Matches
        footerHeight={footerHeight}
          editMode={editModeSpanishLeague}
          setEditMode={setEditModeSpanishLeague}
          userAddedPredictions={
            spanishPredictionsForNextRound?.length
              ? spanishPredictionsForNextRound
              : null
          }
          league="la-liga"
          matches={isSubmissionAllowed && spanish_Matches_NextRound}
          nextRoundNumber={spanish_RoundNumber_NextRound}
          roundId={spanish_RoundId_NextRound}
          nextRound
          savedPredictions={savedPredictions}
        ></Matches>
      </div>
    );
  } else if (spanish_Matches_LastRound?.length) {
    return (
      <div>
        <Matches
        footerHeight={footerHeight}
          editMode={editModeSpanishLeague}
          setEditMode={setEditModeSpanishLeague}
          userAddedPredictions={
            spanishPredictionsForLastRound?.length
              ? spanishPredictionsForLastRound
              : null
          }
          league="la-liga"
          matches={isSubmissionAllowed && spanish_Matches_LastRound}
          nextRoundNumber={spanish_RoundNumber_NextRound}
          lastRound={true}
          roundId={spanish_RoundId_LastRound}
          nextRound
          savedPredictions={savedPredictions}
        ></Matches>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner
          className="spinner"
          animation="border"
          style={{ color: "rgba(121, 192, 0, 1)" }}
        />
      </div>
    );
  }
}

export default SpanishLeague;
