export const leaguesEndpoints = {
  LEAGUE1: "Liga-mx",
  LEAGUE2: "premier-league",
  LEAGUE3: "LaLiga",
}

export const leaguesIds = {
  ID_MEXICO: "631",
  ID_NORWAY: "463",
  ID_ENGLISH: "237",
  ID_COLOMBIA: "746",
  ID_ISRAEL: "385",
  ID_SPAIN: "538",
}

export const CURRENT_LEAGUE1 = leaguesIds.ID_MEXICO
export const CURRENT_LEAGUE_ENDPOINT_1 = leaguesEndpoints.LEAGUE3
export const CURRENT_LEAGUE_ENDPOINT_2 = leaguesEndpoints.LEAGUE2

export const CURRENT_CAMPAIGN_ID = 1
