import { Container, Row } from "react-bootstrap";
import "./Maintenance.css";
import maintenanceImage from "./maintenance.png";

const Maintenance = () => {
  return (
    <Container fluid className="container__Maintenance">
      <Row>
        <img
          src={maintenanceImage}
          alt="Maintenance_Image"
          className="image__Maintenance"
        />
      </Row>
    </Container>
  );
};

export default Maintenance;
