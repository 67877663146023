import React from "react";
import Nav from "./Nav";
import Banner from "../GeneralComponents/Banner";
import Round from "../Matches/Round";
import MatchesTabs from "./MatchesTabs";
import AccumulatorTabs from "./AccumulatorTabs";
import MediaQuery from "react-responsive";
import DesktopHeader from "./DesktopHeader";
import { PredictionsContext } from "../contexts/PredictionsContext";

const Header = ({
  forwardedRef,
  accumulatorHeader,
  predictionsHeader,
  pageName,
  banner,
  round,
  league,
  userAddedPredictions,
  leaderboard,
  TermsConditions,
  bgColor,
  textColor,
  lastRound = false,
  redirectURL,
}) => {
  return (
    // if not inside prediction page (where we send banner) - style header differently
    <div ref={forwardedRef}
      className={
        !banner
          ? `header-container bg-white header-banner`
          : "border-bottom header-banner"
      }
      style={{
        paddingTop: `env(safe-area-inset-top, 40px)`,
        backgroundColor: "#334049",
        position:"fixed",
        top:0,
        left:0,
        right:0,
        zIndex:1030
      }}
    >
      <MediaQuery minWidth={768}>
        <DesktopHeader
          accumulatorHeader={accumulatorHeader ? true : null}
          leaderboard={leaderboard ? true : null}
          predictionsHeader={predictionsHeader}
          bgColor={bgColor}
          textColor={textColor}
        />
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        <Nav
          TermsConditions={TermsConditions}
          leaderboard={leaderboard}
          banner={banner}
          pageName={pageName}
        ></Nav>
      </MediaQuery>
      {/* if inside accumulator page - show banner */}
      {banner ? (
        <Banner
          banner={banner}
          accumulatorHeader={accumulatorHeader}
          redirectURL={redirectURL}
        />
      ) : null}

      {accumulatorHeader ? (
        <AccumulatorTabs league={league}></AccumulatorTabs>
      ) : null}
      {/* <MediaQuery minWidth={768}>
        {banner ? <Banner banner={banner}></Banner> : null}
      </MediaQuery> */}
      <MediaQuery maxWidth={768}>
        {predictionsHeader ? <MatchesTabs></MatchesTabs> : null}
      </MediaQuery>

      {predictionsHeader && (
        <Round
          currentRound={round}
          userAddedPredictions={userAddedPredictions}
          lastRound={lastRound}
        ></Round>
      )}
      <MediaQuery minWidth={768}>
        {predictionsHeader ? (
          <div
            className="bg-white"
            style={{
              width: 400,
            }}
          >
            <MatchesTabs bigScreen={true}></MatchesTabs>
          </div>
        ) : null}
      </MediaQuery>
    </div>
  );
};

export default Header;
