// let BASE_URL = "http://10.0.0.24:8080";
// let BASE_URL = "https://codere.prod.copa.hook-apps.com";
let BASE_URL = "https://qa.hook-apps.com";
// let BASE_URL = "https://codere.hook-apps.com";
let LOGIN_URL =
  "https://codere-stg-admin.mhub2.com/login?login_action=/mvp-de-codere";

export { BASE_URL, LOGIN_URL };

export const shareUrlWithFriends = "https://mxcodere.hook-apps.com/";
export const oneLinkStore = "https://codere.onelink.me/Nw8D/f2afc44f";
// export const iosAppId = "1576682320";
// export const androidAppId = "mx.codere.mvp";
