import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Share from "./components/Share/Share";
import { PredictionsContext } from "./contexts/PredictionsContext";
import { SideNavContext } from "./contexts/SideNavContext";
import { UserContext } from "./contexts/UserContext";
import { shareUrlWithFriends, oneLinkStore } from "./environment";
import tour from "./images/mobile-footer/tour.png";
import GreenInvite from "./images/sideNavDesktop/GreenInvite.svg";
import termsGreen from "./images/sideNavDesktop/Terms-Green.png";
import ModalExit from "./Modal/ModalExit";

const SideNav = ({ platforms }) => {
  const { sideBar, setSidebar } = useContext(SideNavContext);
  const { isModify, setModify } = useContext(PredictionsContext);
  const { isInApp } = useContext(UserContext);
  const {
    params: { firstParam, secondParam },
  } = useRouteMatch({
    path: ["/:firstParam/:secondParam", "/:firstParam", "/"],
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [moveTo, setMoveTo] = useState(false);

  const history = useHistory();
  const sideNavRef = useRef();

  let paddingTop;
  if (["1x2", "u-o-2.5-goals", "bbts"].includes(secondParam)) {
    paddingTop = "18rem";
  } else if (firstParam === "leaderboard") {
    paddingTop = "14rem";
  } else if (firstParam === "results-schedule") {
    paddingTop = "11rem";
  } else if (firstParam === "terms-conditions") {
    paddingTop = "9rem";
  } else if (firstParam === "how-to-play") {
    paddingTop = "7rem";
  } else {
    paddingTop = "17rem";
  }

  //this function invokes from the native to let us know if the postMessage was successfull
  window.receivedPostMsg = () => {
    setShareLoading(false);
  };
  const clickShare = () => {
    setShareLoading(true);
    if (isInApp) {
      sessionStorage.getItem("os") === "ios"
        ? window?.webkit?.messageHandlers?.observer?.postMessage(
            JSON.stringify({
              data: {
                title:
                  "Dile a tu cuate que descargue MVP y rétalo en la quiniela semanal",
                onelink: oneLinkStore,
              },
            })
          )
        : window.Android.postMessage(
            JSON.stringify({
              url: "goToStore",
              data: {
                title:
                  "Dile a tu cuate que descargue MVP y rétalo en la quiniela semanal",
                onelink: oneLinkStore,
              },
            })
          );
    } else {
      setShareLoading(false);
      setShowShareModal(true);
    }
  };

  //prediction and accumulator positioning
  let navContainerStyle = {
    transition: "left 400ms ease-in",
    position: "fixed",
    backgroundColor: "#FFFFFF",
    left: "-6rem",
    bottom: 0,
    top: 0,
    boxShadow: "5px 0px 10px rgba(180, 180, 180, 0.25)",
    zIndex: 200,
    marginTop: -30,
    width: "6rem",
    paddingTop: `calc(${paddingTop} + env(safe-area-inset-top, 600))`,
  };

  const navContainerActive = {
    ...navContainerStyle,
    transition: "400ms ease-out",
    left: 0,
  };

  const imgStyle = {
    transform: "translateX(-50%)",
    left: "50%",
    position: "relative",
  };

  const text = {
    fontSize: 12,
    textAlign: "center",
    color: "rgba(91, 91, 91, 1)",
    marginBottom: 0,
  };

  const textTour = {
    color: "rgba(121, 192, 0, 1)",
    fontSize: 12,
    textAlign: "center",
    marginBottom: 0,
  };

  const navItem = {
    paddingTop: 17,
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
        setSidebar(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sideNavRef, setSidebar]);

  const goToDeposite = () => {
    window.open(
      "https://www.codere.mx/deposito?clientType=sportsbook&amp;back_url=https%3A%2F%2Fbet.codere.mx%2Fhub-landing&amp;deliveryPlatform=Native"
    );
  };

  const moveToHowToPlay = () => {
    setSidebar(false);
    if (isModify) {
      setShow(true);
      setMoveTo("how-to-play");
    } else history.push(`/how-to-play`);
  };
  const moveToTermConditions = () => {
    setSidebar(false);
    if (isModify) {
      setShow(true);
      setMoveTo("terms-conditions");
    } else history.push(`/terms-conditions`);
  };

  const backHandler = () => {
    setShow(false);
    setModify(false);
    if (moveTo) {
      history.push(`/${moveTo}`);
    }
    return;
  };

  return (
    <div ref={sideNavRef}>
      <ModalExit ready={show} setReady={setShow} back={backHandler} />
      <div
        className="nav-container"
        style={sideBar ? navContainerActive : navContainerStyle}
      >
        <ul
          style={{ paddingTop: "env(safe-area-inset-top)" }}
          className="navbar-nav"
        >
          <li style={navItem} className="nav-item">
            <div className="nav-link">
              <img
                onClick={moveToHowToPlay}
                src={tour}
                alt="how to play"
                style={imgStyle}
              />
              <p style={textTour}>Cómo jugar</p>
            </div>
            {/* </Link> */}
          </li>
          <li className="nav-item">
            <div className="nav-link">
              <img
                onClick={moveToTermConditions}
                src={termsGreen}
                alt=""
                style={imgStyle}
              />
              <p style={textTour}>Terms & conditions</p>
            </div>
          </li>
          <li className="nav-item">
            <div className="dektop-sidenav-img" onClick={clickShare}>
              <img style={{ width: "41px" }} src={GreenInvite} alt="share" />
              <p style={textTour}>
                {" "}
                {shareLoading ? "Loading" : "Invita a tu dupla"}
              </p>
            </div>
          </li>
        </ul>
      </div>
      {showShareModal && (
        <Share
          title={
            <>
              <span>
                Dile a tu cuate que descargue MVP
                <br />y rétalo en la quiniela semanal.
              </span>
            </>
          }
          linkUrl={shareUrlWithFriends}
          platforms={platforms}
          ready={showShareModal}
          setReady={setShowShareModal}
        />
      )}
    </div>
  );
};

export default SideNav;
