import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  createRef,
  useRef,
} from "react";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import Match from "./Match";
import CostumButton from "../GeneralComponents/Button";
import axios from "axios";
import { BASE_URL, LOGIN_URL } from "../environment";
import Header from "../Header/Header";
import { Spinner } from "react-bootstrap";
import ModalSubmit from "../Modal/ModalSubmit";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";
import moment from "moment";
import MatchesSuccessIndicators from "../GeneralComponents/MatchesSuccessIndicators";
import localforage from "localforage";
import "moment/locale/es";
import NicknameModal from "../Modal/NicknameModal";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import EditBtn from "../GeneralComponents/EditBtn";
import { UserContext } from "../contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import BannerSizeEnum from "../enums/banners-size";
import Share from "../components/Share/Share";
import { Platforms } from "../components/Share/PlatfromsUrls";
import PagesEnum from "../enums/pages";
import { sharePredictionsHandler } from "../helpers/share-predictions";
import { useDebounce } from "../hooks/useDebounce";
import { SideNavContext } from "../contexts/SideNavContext";
import CountDown from "../components/CountDown";

// import ReactGA from "react-ga";

moment.updateLocale("es", {
  weekdaysShort: "dom_lun_mar_mié_jeu_vie_sáb".split("_"),
});

const Matches = ({
  userAddedPredictions,
  league,
  matches,
  roundId,
  currentRound,
  nextRound,
  editMode,
  setEditMode,
  savedPredictions,
  nextRoundNumber,
  spanishEntriesFrom,
  lastRound = false,
  footerHeight,
}) => {
  const { apiCallWasMade, setApiCallWasMade, setModalActive, setIsDataOld } =
    useContext(ResultsScheduleContext);
  const { sideBar } = useContext(SideNavContext);

  // useEffect(() => {
  //   const entriesFrom = moment(spanishEntriesFrom).format("ddd, D MMMM YYYY");
  // }, [spanishEntriesFrom]);

  const isXsmall = useMediaQuery({ query: "(max-width: 599px)" });
  const isSmall = useMediaQuery({ query: "(min-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isLarge = useMediaQuery({ query: "(min-width: 1024px)" });
  const isXlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const {
    loading,
    setPrediction,
    setLeague,
    predictionArray,
    isAfterLocalForage,
    setIsAfterLocalForage,
    setSavedPredictions,
    banners,
    setModify,
    isModify,
    spanishCurrentRoundAndSubmission,
    spanishLastRoundAndSubmission,
    countDownData,
    showCountDown,
    setShowCountDown,
  } = useContext(PredictionsContext);

  const { user, hasNickname, checkedNickname, isInApp } =
    useContext(UserContext);

  const { spanishNextRound } = useContext(ResultsScheduleContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [postOrPut, setPostOrPut] = useState("post");
  const [renderBtn, setRenderBtn] = useState(false);
  const [accum1x2, setAccum1x2] = useState(null);
  const [entriesFrom, setEntriesFrom] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [newUserBanner, setNewUserBanner] = useState(null);
  const [currentUserBanner, setCurrentUserBanner] = useState(null);
  const [shareLoading, setShareLoading] = useState(false);

  // Share Predictions Snapshot
  const [image, setImage] = useState(null);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [blob, setBlob] = useState({});

  //show/hide Predictions-Modal
  const [showNicknameModal, setShowNicknameModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const headerRef = useRef();

  const ref = createRef(null);
  const location = useLocation();
  const history = useHistory();

  //for reseting show counter when the page first renderd
  useEffect(() => {
    setShowCountDown(true);
  }, [setShowCountDown]);

  useEffect(() => {
    if (banners) {
      if (isXsmall) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xs &&
              item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xs &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isSmall) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.s && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.s &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isTablet) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.m && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.m &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isLarge) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.l && item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.l &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
      if (isXlarge) {
        setNewUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xl &&
              item.img_url.includes("NewUser")
          ).img_url
        );
        setCurrentUserBanner(
          banners.find(
            (item) =>
              item.size === BannerSizeEnum.xl &&
              item.img_url.includes("CurrentUser")
          ).img_url
        );
      }
    }
  }, [banners, isLarge, isSmall, isTablet, isXlarge, isXsmall]);

  useEffect(() => {
    !!spanishEntriesFrom &&
      setEntriesFrom(
        moment(spanishEntriesFrom).format("ddd D MMMM [a las] HH:mm")
      );
  }, [spanishEntriesFrom, entriesFrom]);

  //initialy check if render btn
  useEffect(() => {
    if (userAddedPredictions && !editMode) {
      setRenderBtn(false);
    } else {
      setRenderBtn(true);
    }
  }, [userAddedPredictions, editMode]);

  useEffect(() => {
    if (userAddedPredictions && setEditMode && nextRound) {
      setEditMode(false);
    }
  }, []);

  //if user sent predictions, make put request to edit
  useEffect(() => {
    if (userAddedPredictions) {
      setPostOrPut("put");
    } else {
      setPostOrPut("post");
    }
  }, [userAddedPredictions]);

  useEffect(() => {
    setLeague(league);
  }, [league]);

  const handleClose = () => {
    if (!hasNickname) {
      setShowNicknameModal(true);
    } else {
      history.push(`${location.pathname}/1x2`);
    }
  };
  // const handleShowNicknameModal = () => {
  //   setShowNicknameModal(true);
  // };
  //

  //prediction scores array
  const [predictions, setPredictions] = useState();

  //if user sent predictions, make put request to edit
  useEffect(() => {
    if (userAddedPredictions) {
      setPostOrPut("put");
    } else {
      setPostOrPut("post");
    }
  }, [userAddedPredictions, checkedNickname, user]);

  useEffect(() => {
    if (isAfterLocalForage && !hasNickname) {
      setShowNicknameModal(true);
      setIsAfterLocalForage(false);
    }
  }, [isAfterLocalForage, hasNickname, setIsAfterLocalForage]);

  useEffect(() => {
    setSavedPredictions(predictions);
  }, [predictions]);

  const getPrediction = useCallback((prediction, index) => {
    if (prediction.matchId) {
      setPredictions((prevPredictions) => {
        const updated_predictions = [...(prevPredictions || [])];
        updated_predictions[index] = prediction;
        setPrediction(updated_predictions);
        return updated_predictions;
      });
    }
  }, []);
  const predictionReq = {
    email: "blabla@gmail.com",
    roundId: roundId,
    predictions: predictions,
  };

  useEffect(() => {
    const isNullPrediction = predictions?.filter((prediction) => {
      return (
        prediction?.homeGoals !== null &&
        prediction?.awayGoals !== null &&
        prediction?.statusCode !== 4
      );
    });
    const filteredMatches = matches?.filter((match) => match.status_code !== 4);

    if (
      filteredMatches?.length &&
      isNullPrediction?.length === filteredMatches?.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [matches, predictions]);

  //post req for Predictions
  const sendPostReq = async () => {
    setDisabled(true);
    const filteredPredictionsArray = predictions.filter(
      (prediction) => prediction.statusCode !== 4
    );
    const checkPredictionsArray = filteredPredictionsArray.filter(
      (prediction) => {
        if (
          isNullOrUndefined(prediction.homeGoals) ||
          isNullOrUndefined(prediction.awayGoals)
        ) {
          return true;
        } else {
          return false;
        }
      }
    );
    if (checkPredictionsArray.length > 0) {
      setErrorMessage("Please do not leave an empty prediction.");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setErrorMessage("");
      if (!axios.defaults.headers.common["jwt-token"]) {
        await localforage.setItem("predictionArray", predictionArray);
        await localforage.setItem("nextRoundId", roundId);
        if (isInApp) {
          sessionStorage.getItem("os") === "ios"
            ? window.location.assign(
                `${LOGIN_URL}?advertiser=MVP_iOS_MX&refererurl=https://codere.mx/mvp-de-codere&creferer=BTAG:B38EAA0CCC7E744CB8FA96219018F372?deliveryPlatform=Native&hideHeader=1`
              )
            : window.top.postMessage(
                JSON.stringify({
                  url: "bridgeNativeResgister",
                  data: {
                    link: `https://www.codere.mx/registro-deportes?deliveryPlatform=Native&os=android&advertiser=MVP_Android_MX&profileid=245006&creferer=BTAG:DAE3C1F1BA3F053C11362BF830D13416%3bregProd:MVP&clientType=sportsbook&isMobile=1&login_action=https%3A%2F%2Fwww.codere.mx%2Fdeposito&back_url=https%3A%2F%2Fmxcodere.hook-apps.com`,
                  },
                })
              );
        } else {
          window.top.location.href = `${LOGIN_URL}`;
        }
        return;
      }

      if (postOrPut === "post") {
        axios
          .post(
            `${BASE_URL}/api/add-predictions`,
            predictionReq,

            {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setAccum1x2(
                response?.data?.roundMatches
                  ?.map((match) => {
                    return match?.markets.find(
                      (market) => market.marketType === 1
                    );
                  })
                  ?.map((outcom) => {
                    return outcom?.possibleOutcomes?.find(
                      (selected) => selected.isSelected === true
                    );
                  })
                  ?.map((selected) => selected?.id)
              );
              setApiCallWasMade(!apiCallWasMade);
              setPostOrPut("put");
              setModalActive(true);
              handleClose();
              setModify(false);
              (async () => {
                await localforage.removeItem("nextRoundId");
                await localforage.removeItem("predictionArray");
              })();
            }
            setIsDataOld(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (postOrPut === "put") {
        setShowModal(true);
      }
    }
  };

  const debouncedSubmit = useDebounce(sendPostReq);

  const redirectBanner = () => {
    const os = sessionStorage.getItem("os");
    if (!user) {
      if (os) {
        if (os === "ios") {
          window.open(
            "https://www.codere.mx/login?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_iOS_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
            "_blank"
          );
        } else {
          window.open(
            "https://www.codere.mx/login?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_Android_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://www.codere.mx/registro-deportes?clientType=sportsbook&mvp=1&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere&login_action=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere",
          "_blank"
        );
      }
    } else {
      history.push(`${location.pathname}/1x2`);
      return;
    }
  };

  const checkedPredictions = userAddedPredictions?.reduce(
    (acc, curr) => acc + (curr.status !== "pending" ? 1 : 0),
    0
  );

  const isAndroid = sessionStorage.getItem("os") === "android";

  //this function invokes from the native to let us know if the postMessage was successfull
  window.receivedPostMsg = () => {
    setShareLoading(false);
  };
  const shareHandler = () => {
    if (sideBar) return;
    setShareLoading(true);
    sharePredictionsHandler(
      ref,
      setIsDisplayed,
      PagesEnum.predictions,
      setBlob,
      setImage,
      setShareLoading,
      roundId
    );
  };
  let headerHeight = headerRef?.current?.clientHeight + 20;

  if (!loading) {
    return (
      <div
        className="mtches-body min-vh-100"
        //if CountDown is shown the padding bottom will be the CounDown height
        style={
          showCountDown
            ? { paddingBottom: "3.75rem", paddingTop: headerHeight }
            : { paddingTop: headerHeight }
        }
      >
        <div
          style={{ position: "relative" }}
          className="px-2 container-md flex-wrap"
        >
          <div
            ref={ref}
            style={{ paddingTop: `env(safe-area-inset-top, 50px)` }}
          >
            <Header
              forwardedRef={headerRef}
              predictionsHeader={true}
              userAddedPredictions={userAddedPredictions}
              lastRound={lastRound}
            ></Header>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="edit-prediction-btn">
                {!lastRound && (
                  <EditBtn
                    userAddedPredictions={userAddedPredictions}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                )}
              </div>
            </div>
            {!nextRound && !!entriesFrom && (
              <div className="text-center">
                <p style={{ color: "#79C000" }}>
                  Pronósticos para la siguiente jornada se abrirán el &nbsp;
                  {entriesFrom}
                </p>
              </div>
            )}

            {!matches && !loading ? (
              <h2 className="text-center">
                There are currently no predictions to show
              </h2>
            ) : (
              <div>
                {!nextRound && checkedPredictions ? (
                  <div>
                    <MatchesSuccessIndicators
                      submissionResults={userAddedPredictions}
                      matches={matches}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {showCountDown ? (
                  <div className="w-100 d-flex justify-content-center ">
                    <CountDown
                      footerHeight={footerHeight}
                      enableClose={true}
                      onClose={() => setShowCountDown(false)}
                      counterPosition="bottom"
                      nextRoundName={countDownData.roundName}
                      nextRoundTime={countDownData.roundTime}
                      entriesUntilOrFrom={countDownData.entriesUntilOrFrom}
                    />
                  </div>
                ) : !isTablet ? (
                  <></>
                ) : (
                  <>
                    {currentRound && (
                      <div
                        data-html2canvas-ignore
                        onClick={redirectBanner}
                        style={{ marginTop: isTablet && !isLarge && "3.75rem" }}
                      >
                        <img
                          style={{ width: "100%", cursor: "pointer" }}
                          src={user ? currentUserBanner : newUserBanner}
                          alt="predictions banner"
                        />
                      </div>
                    )}
                  </>
                )}

                {currentRound && !isTablet && (
                  <div
                    data-html2canvas-ignore
                    onClick={redirectBanner}
                    style={{ marginTop: isTablet && !isLarge && "3.75rem" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        marginTop: "1rem",
                      }}
                      src={user ? currentUserBanner : newUserBanner}
                      alt="predictions banner"
                    />
                  </div>
                )}
                {matches && (
                  <div>
                    {matches
                      .sort(
                        (a, b) =>
                          new Date(a.match_start) - new Date(b.match_start)
                      )
                      .map((match, index, array) => (
                        <Match
                          userAddedPrediction={
                            userAddedPredictions?.length
                              ? userAddedPredictions.find(
                                  (prediction) =>
                                    prediction.matchId === match.id
                                )
                              : undefined
                          }
                          statusCode={match.status_code}
                          key={match.id}
                          homeTeamName={match.homeTeam.name}
                          awayTeamName={match.awayTeam.name}
                          match_start={moment(match?.match_start).format(
                            "ddd, D MMMM YYYY"
                          )}
                          filterdDates={
                            moment(match?.match_start).format(
                              "ddd, Do MMM YYYY"
                            ) !==
                            moment(array[index - 1]?.match_start).format(
                              "ddd, Do MMM YYYY"
                            )
                              ? moment(match.match_start).format(
                                  "ddd, D MMMM YYYY"
                                )
                              : null
                          }
                          match_hour={match.match_start}
                          matchId={match.id}
                          getPrediction={getPrediction}
                          league={league}
                          index={index}
                          awayTeamShirt={match?.awayTeam?.shirt_img}
                          homeTeamShirt={match?.homeTeam?.shirt_img}
                          matchScore={match?.matchScore}
                          currentRound={currentRound}
                          nextRound={nextRound}
                          editMode={editMode}
                          lastRound={lastRound}
                          savedPrediction={savedPredictions?.find(
                            (sp) => sp.matchId === match.id
                          )}
                        />
                      ))}
                  </div>
                )}
              </div>
            )}
            {!renderBtn ? (
              <CostumButton goTo={league}>
                <button
                  disabled={sideBar}
                  onClick={shareHandler}
                  data-target="#myModal"
                  className={`button${
                    shareLoading || sideBar ? "-disabled" : ""
                  }`}
                  style={{
                    marginBottom: !isAndroid ? "6rem" : "1.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  {shareLoading ? "Loading..." : "Compartir mis pronósticos"}
                </button>
              </CostumButton>
            ) : !nextRound ? (
              <div style={{ marginBottom: "6rem" }}></div>
            ) : (
              <></>
            )}
            {nextRound && renderBtn && (
              <CostumButton>
                <button
                  disabled={disabled}
                  onClick={debouncedSubmit}
                  data-target="#myModal"
                  className={`${disabled ? "button-disabled" : "button"}`}
                  style={{
                    marginBottom: !isAndroid ? "6rem" : "1.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  GUARDAR
                </button>
              </CostumButton>
            )}
            {isDisplayed && (
              <Share
                blob={blob}
                ready={isDisplayed}
                title="Compartir mis pronósticos"
                imageUrl={image}
                setReady={setIsDisplayed}
                platforms={Platforms}
              />
            )}
            <ModalSubmit
              roundId={roundId}
              ready={showModal}
              setReady={setShowModal}
              afterClose={handleClose}
              setDisabled={setDisabled}
            />
            <NicknameModal
              onCloseModal={() => {
                history.push(`${location.pathname}/1x2`);
              }}
              isOpen={showNicknameModal}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner
          className="spinner"
          animation="border"
          style={{ color: "rgba(121, 192, 0, 1)" }}
        />
      </div>
    );
  }
};

export default Matches;
