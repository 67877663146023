import React, { useContext } from "react";
import hamburger from "../images/hamburger.svg";
import codereLogo from "../images/header/MVPCodereForm.png";
import { SideNavContext } from "../contexts/SideNavContext";
import { UserContext } from "../contexts/UserContext";


function Nav({ banner, pageName, leaderboard, TermsConditions }) {
  const { sideBar, setSidebar } = useContext(SideNavContext);
  const navbarStyle = {
    backgroundColor: "#333F48",
    paddingTop: `env(safe-area-inset-top, 40px)`,
    justifyContent: "space-between",
  };

  const { isInApp } = useContext(UserContext);

  let arrowStyle;

  if (!isInApp) {
    arrowStyle = {
      color: "white",
      marginRight: "0.2em",
      fontSize: "2em",
      height: 20,
    };
  }

  if (isInApp) {
    arrowStyle = {
      color: "white",
      marginRight: "0.2em",
      fontSize: "2em",
      height: 20,
      visibility: "hidden",
    };
  }

  const clickNavBar = (event) => {
    event.stopPropagation();
    setSidebar(!sideBar);
  };

  return (
    <div
      className={
        banner ? "nav-shadow pb-4 container-fluid pt-4" : "container-fluid pt-4"
      }
      style={
        leaderboard || TermsConditions
          ? { backgroundColor: "#333F48", paddingBottom: 13 }
          : { backgroundColor: "#333F48" }
      }
    >
      <div style={navbarStyle}>
        <nav style={navbarStyle} className="d-flex py-0">
          {isInApp && (
            <React.Fragment>
              <div>
                <div
                  style={{ marginTop: 8 }}
                  onClick={(event) => clickNavBar(event)}
                >
                  <img src={hamburger} alt="hamburger menu" />
                </div>
              </div>

              <div className="predictions mx-5 mb-0">
                <img src={codereLogo} alt="logo" />
              </div>
              <div>
                <a
                  style={{ visibility: "hidden" }}
                  href="https://www.codere.mx/yak"
                  target="_top"
                >
                  &rarr;
                </a>
              </div>
            </React.Fragment>
          )}
          {!isInApp && (
            <React.Fragment>
              <div>
                <a
                data-html2canvas-ignore
                  style={arrowStyle}
                  href="https://www.codere.mx/yak"
                  target="_top"
                >
                  &larr;
                </a>
              </div>
              <div className="predictions  mx-5 mb-0">
                <img src={codereLogo} alt="logo" />
              </div>
              <div style={{ marginTop: 8 }} onClick={clickNavBar}>
                <img data-html2canvas-ignore src={hamburger} alt="hamburger menu" />
              </div>
            </React.Fragment>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Nav;
