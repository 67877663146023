import React, { useContext } from "react";
import Matches from "../Matches/Matches";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";


function EnglishLeague() {
  const {
    english_Matches_NextRound,
    english_RoundNumber_NextRound,
    english_RoundId_NextRound,
    english_Matches_CurrentRound,
    spanish_RoundNumber_CurrentRound,
    spanish_RoundId_CurrentRound,
    english_Predictions_CurrentRound,
  } = useContext(PredictionsContext);

  const { englishPredictionsForNextRound } = useContext(ResultsScheduleContext);

  if (english_Matches_CurrentRound) {
    return (
      <div>
        <Matches
          userAddedPredictions={
            english_Predictions_CurrentRound?.length
              ? english_Predictions_CurrentRound
              : null
          }
          league="premier-league"
          matches={english_Matches_CurrentRound}
          round={spanish_RoundNumber_CurrentRound}
          roundId={spanish_RoundId_CurrentRound}
          currentRound
        ></Matches>
      </div>
    );
  } else {
    return (
      <div>
        <Matches
          userAddedPredictions={
            englishPredictionsForNextRound?.length
              ? englishPredictionsForNextRound
              : null
          }
          league="premier-league"
          matches={english_Matches_NextRound}
          round={english_RoundNumber_NextRound}
          roundId={english_RoundId_NextRound}
          nextRound
        ></Matches>
      </div>
    );
  }
}

export default EnglishLeague;
