import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BASE_URL } from "../environment";
import { PredictionsContext } from "./PredictionsContext";
import { ResultsScheduleContext } from "./ResultsScheduleContext";
import moment from "moment";
import {
  CURRENT_CAMPAIGN_ID,
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "../leagues/leagues";
import PagesEnum from "../enums/pages";

export const AccumulatorContext = createContext(null);

export function AccumulatorProvider({ children }) {
  const [accumulator, setAccumulator] = useState(null);
  const [banners, setBanners] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let jwt = localStorage.getItem("jwt-token");
    const params = {
      campaignId: CURRENT_CAMPAIGN_ID,
      page: PagesEnum.parlays,
    };
    axios
      .post(`${BASE_URL}/api/get-banners`, params, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
      })
      .then((res) => {
        console.log({ res });
        setBanners(res?.data?.banners);
      });
  }, []);

  // useEffect(() => {
  //   let jwt = localStorage.getItem("jwt-token")
  //   axios
  //     .get(`${BASE_URL}/api/get-parlays-banner`, {
  //       headers: {
  //         "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
  //         "jwt-token": jwt,
  //       },
  //     })
  //     .then((res) => {
  //       setBanner(res?.data)
  //     })
  // }, [])

  const {
    nextEnglishRoundSubmission,
    nextSpanishRoundSubmission,
    currentSpanishRoundSubmission,
  } = useContext(ResultsScheduleContext);

  const {
    english_RoundId_NextRound,
    english_RoundId_CurrentRound,
    spanish_RoundId_NextRound,
    spanish_RoundId_CurrentRound,
    spanishCurrentRoundAndSubmission,
    englishCurrentRoundAndSubmission,
  } = useContext(PredictionsContext);

  const location = useLocation();

  useEffect(() => {
    let roundId, submissionObj;
    switch (location.pathname) {
      case `/${CURRENT_LEAGUE_ENDPOINT_1}/1x2`:
        roundId = spanish_RoundId_CurrentRound || spanish_RoundId_NextRound;
        submissionObj = spanishCurrentRoundAndSubmission?.submission
          ? spanishCurrentRoundAndSubmission
          : nextSpanishRoundSubmission;
        break;
      case `/${CURRENT_LEAGUE_ENDPOINT_2}/1x2`:
        roundId = english_RoundId_CurrentRound || english_RoundId_NextRound;
        submissionObj = englishCurrentRoundAndSubmission?.submission
          ? englishCurrentRoundAndSubmission
          : nextEnglishRoundSubmission;
        break;
      case `/${CURRENT_LEAGUE_ENDPOINT_1}`:
        roundId = spanish_RoundId_CurrentRound || spanish_RoundId_NextRound;
        submissionObj = spanishCurrentRoundAndSubmission?.submission
          ? spanishCurrentRoundAndSubmission
          : nextSpanishRoundSubmission;
        break;
      case `/${CURRENT_LEAGUE_ENDPOINT_2}`:
        roundId = english_RoundId_CurrentRound || english_RoundId_NextRound;
        submissionObj = englishCurrentRoundAndSubmission?.submission
          ? englishCurrentRoundAndSubmission
          : nextEnglishRoundSubmission;
        break;

      default:
    }
    if (!roundId || !submissionObj) {
      setLoading(false);
      return;
    }

    let jwt = localStorage.getItem("jwt-token");

    axios
      .get(`${BASE_URL}/api/get-top-six-with-odds-mx`, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
        params: {
          submissionId: submissionObj?.submission?.id,
          roundId,
        },
      })
      .then((res) => {
        setLoading(false);
        const relevantRoundMatches = res?.data?.roundMatches?.filter((rm) =>
          moment(rm.match_start).isAfter(moment())
        );
        res?.data &&
          setAccumulator({ ...res?.data, roundMatches: relevantRoundMatches });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [location, nextEnglishRoundSubmission, nextSpanishRoundSubmission]);

  return (
    <AccumulatorContext.Provider
      value={{ banners, accumulator, loading, setAccumulator }}
    >
      {children}
    </AccumulatorContext.Provider>
  );
}
