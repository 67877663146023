import React, { useContext, useState } from "react";
import MediaQuery from "react-responsive";
import "./howToPlay.css";
import header from "../images/howToPlay/header.png";
import bigImage from "../images/howToPlay/bigImage.png";
import squers from "../images/howToPlay/squers.png";
import mouse from "../images/howToPlay/mouse.png";
import Clock from "../images/howToPlay/Clock.png";
import hamburger from "../images/hamburger.svg";

import { SideNavContext } from "../contexts/SideNavContext";
import { PredictionsContext } from "../contexts/PredictionsContext";
import { UserContext } from "../contexts/UserContext";

const HowToPlay = () => {
  const { spanish_RoundNumber_CurrentRound, spanish_RoundNumber_NextRound } =
    useContext(PredictionsContext);

  const { isInApp } = useContext(UserContext);

  const { sideBar, setSidebar } = useContext(SideNavContext);
  const [show, setShow] = useState(false);
  const { isModify } = useContext(PredictionsContext);

  const clickNavBar = (event) => {
    event.stopPropagation();
    if (!isModify) {
      setSidebar(!sideBar);
    } else {
      setShow(true);
    }
  };

  return (
    <div className="component-container">
      <MediaQuery maxWidth={768}>
        {!isInApp && (
          <header className="d-flex mobile-header">
            {/* {+sessionStorage.getItem("isMobile") === 1  ? :} */}
            <div
              // style={isInApp ? { visibility: "hidden" } : { display: "block" }}
              className="back-arrow-container"
            >
              <a
                className="back-arrow"
                href="https://www.codere.mx/yak"
                target="_top"
              >
                &larr;
              </a>
            </div>
            <div className="header-image-container">
              <img className="header-image" src={header} alt="header" />
            </div>
            <div>
              <div
                style={{ marginTop: 8 }}
                onClick={(event) => clickNavBar(event)}
              >
                <img src={hamburger} alt="hamburger menu" />
              </div>
            </div>
          </header>
        )}
        {isInApp && (
          <header className="d-flex mobile-header">
            {/* {+sessionStorage.getItem("isMobile") === 1  ? :} */}
            <div
              style={{ marginTop: 8 }}
              onClick={(event) => clickNavBar(event)}
            >
              <img src={hamburger} alt="hamburger menu" />
            </div>
            <div className="header-image-container">
              <img className="header-image" src={header} alt="header" />
            </div>
            <div>
              <div
              // style={isInApp ? { visibility: "hidden" } : { display: "block" }}
              ></div>
            </div>
          </header>
        )}
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <header className="d-flex desktop-header">
          <div className="desktop-flex-2"></div>
          <div className="desktop-flex-1">
            <h1 className="desktop-headline">¿Cómo jugar?</h1>
          </div>
          <div className="desktop-flex-1"></div>
        </header>
      </MediaQuery>
      <section className="section-one ">
        <img className="big-image" src={bigImage} alt="big image" />
        <div className="section-one-general-text container-fluid">
          <div className="inner-box-section-one">
            <p className="section-one-white-text">
              Haz tu pronóstico a marcador exacto a los partidos de fútbol
            </p>
            <p className="section-one-green-text">
              Entre más aciertos logres, obtendrás mejores premios
            </p>
          </div>
        </div>
      </section>
      <section className="section-two container">
        <h1 className="green-headline">
          ¿Cómo puedes realizar tus pronósticos?
        </h1>
        <div className="logos-section">
          <div className="logos mouse d-flex">
            <img className="img-logo" src={mouse} alt="" />
            <p>
              Simplemente debes hacer clic en cada una de las casillas para
              indicar el marcador exacto.
            </p>
          </div>
          <div className=" logos squere d-flex">
            <img className="img-logo" src={squers} alt="" />
            <div className="d-block-paragraphs">
              <p>
                Podrás crear y modificar los pronósticos antes del comienzo del
                primer partido de cada jornada.
              </p>
              <p className="small-p">
                Una vez iniciado no se admitirán más participaciones.
              </p>
            </div>
          </div>
          <div className="logos clock d-flex">
            <img className="img-logo" src={Clock} alt="" />
            <p>
              Sólo se tendrán en cuenta los goles marcados en los 90 minutos de
              juego y no los anotados en el tiempo extra.
            </p>
          </div>
        </div>

        <div className="premios container">
          <h1 className="premios-headline">Premios</h1>
          <p className="premios-p">
            A partir de tres aciertos, podrás ser acreedor a los siguientes
            premios:
          </p>
        </div>
      </section>
      <div className="premios-headline-2">
        <div>
          {spanish_RoundNumber_CurrentRound || spanish_RoundNumber_NextRound}:
        </div>
      </div>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>3 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p className="">$400 freebets</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>4 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>Jersey fútbol (RMA o Rayados)</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>5 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>XBOX</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>6 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>Experiencia VIP RAYADOS</p>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="premios-headline-2">
        <div>Repechaje:</div>
      </div>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>3 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p className="">$400 en freebets</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>4 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>Playera de fútbol</p>
            </div>
          </div>
        </div>
      </section>
      <div className="premios-headline-2">
        <div>Cuartos (Ida y vuelta):</div>
      </div>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>3 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p className="">$400 en freebets</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>4 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>Playera de fútbol</p>
            </div>
          </div>
        </div>
      </section>
      <div className="premios-headline-2">
        <div>Semifinal (Ida y vuelta):</div>
      </div>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>3 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p className="">$400 en freebets</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>4 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>Playera de fútbol</p>
            </div>
          </div>
        </div>
      </section>
      <div className="premios-headline-2">
        <div>Final (Ida y vuelta):</div>
      </div>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>2 aciertos</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p className="">$500 en freebets</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-four container">
        <p>
          Además, los usuarios que participen en la promoción en semanas
          consecutivas podrán obtener:
        </p>
      </section>
      <section className="section-three p-3 container-fluid">
        <div className=" container">
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>2 semanas seguidas</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">$40 en freebets</div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>3 semanas seguidas</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>$40 en freebets</p>
            </div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>4 semanas seguidas</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">$80 en freebets</div>
          </div>
          <div className="d-flex section-three-line">
            <div className="section-three-box">
              <p>5 semanas seguidas</p>
            </div>
            <div className="text-arrow section-three-box">{`>>>>`}</div>
            <div className="section-three-box">
              <p>$100 en freebets</p>
            </div>
          </div>
        </div>
      </section>
      <div className="last-p">
        <p className="small-font">
          *Estas freebets se entregarán al finalizar la jornada en un plazo
          máximo de 3 días laborales.
        </p>
      </div>

      <section>
        <section>
          <div className="d-flex"></div>
          <div className="d-flex"></div>
          <div className="d-flex"></div>
          <div className="d-flex"></div>
        </section>
        <p className="small-font"></p>
      </section>
    </div>
  );
};

export default HowToPlay;
