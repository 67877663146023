import "./RSbody.css";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import hapyTeam from "../images/results/hapyTeam.png";
import { ResultsScheduleContext } from "../contexts/ResultsScheduleContext";
import { UserContext } from "../contexts/UserContext";
import PastResults from "./PastResults";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import moment from "moment";
import Banner from "../GeneralComponents/Banner";
import { PredictionsContext } from "../contexts/PredictionsContext";
import "moment/locale/es";
import BannerSizeEnum from "../enums/banners-size";

import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "../leagues/leagues";
import isNullOrUndefined from "./../helpers/is-null-or-undefined";
import DesktopHeader from "../Header/DesktopHeader";
import CountDown from "../components/CountDown";

moment.updateLocale("es", {
  weekdaysShort: "dom_lun_mar_mié_jeu_vie_sáb".split("_"),
});

const linkStyle = { textDecoration: "none", color: "rgba(53, 61, 73, 1)" };

const whatRoundStyle = {
  fontWeight: 500,
  fontSize: 16,
  marginBottom: 0,
  color: "#FFFFFF",
};

const nextRoundLeftBox = {
  background: "linear-gradient(155.76deg, #353D49 2.12%, #3C5C8B 104.2%)",
  borderRadius: "10px 0px  0px 10px",
};

const untillWhenStyle = {
  ...whatRoundStyle,
  fontWeight: 700,
};

const textDivStyle = {
  position: "relative",
  marginTop: "3em",
};

const backgroungImage = {
  backgroundImage: "url(" + hapyTeam + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundColor: "black",
  borderRadius: "0 10px 10px 0",
};

const borders = {
  width: 10,
  padding: 0,
  height: 0.5,
  background: "rgba(218, 218, 218, 1)",
  display: "inline",
  marginTop: 10,
};

const formatEntriesDate = (date) => {
  return date instanceof Date && !isNaN(date)
    ? moment(date).format("ddd, MMMM D LT")
    : "";
};

const RSbody = () => {
  const {
    englishPastRoundsPredictions,
    spanishPastRoundPredictions,
    englishNextRound,
    spanishNextRound,
    spanishNextRoundName,
    banners,
  } = useContext(ResultsScheduleContext);

  const {
    englishCurrentRoundAndSubmission,
    spanishCurrentRoundAndSubmission,
    spanishLastRoundAndSubmission,
    spanishEntriesFrom,
    spanishEntriesUntil,
    spanish_RoundNumber_CurrentRound,
    spanish_RoundNumber_NextRound,
    countDownData,
  } = useContext(PredictionsContext);

  const { user } = useContext(UserContext);
  let location = useLocation();
  let history = useHistory();
  let {
    params: { league: currentLeagueInRoute },
  } = useRouteMatch("/results-schedule/:league");
  const [nextRoundName, setNextRoundName] = useState(null);
  const [nextRoundTime, setNextRoundTime] = useState(moment());
  const [isEntriesUntilOrFrom, setIsEntriesUntilOrFrom] = useState("until");
  const [showCountDown, setShowCountDown] = useState(true);
  const [banner, setBanner] = useState(null);
  const [bannerRedirectURL, setBannerRedirectURL] = useState(null);
  const isXsmall = useMediaQuery({ query: "(max-width: 500px)" });
  const isSmall = useMediaQuery({ query: "(min-width: 500px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 600px)" });
  useEffect(() => {
    if (banners) {
      if (isXsmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.xs).img_url
        );
      }
      if (isSmall) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.s).img_url
        );
      }
      if (isTablet) {
        setBanner(
          banners.find((item) => item.size === BannerSizeEnum.m).img_url
        );
      }
    }
  }, [banners, isSmall, isTablet, isXsmall]);

  useEffect(() => {
    const os = sessionStorage.getItem("os");
    if (os) {
      if (os === "ios") {
        setBannerRedirectURL(
          "https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_iOS_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere"
        );
      } else {
        setBannerRedirectURL(
          "https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_Android_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere"
        );
      }
    } else {
      setBannerRedirectURL(
        "https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&mvp=1&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere&login_action=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere"
      );
    }
  }, []);

  //choosing what round starting time to display according to league url
  function getNextRoundTime() {
    return spanishCurrentRoundAndSubmission
      ? spanishCurrentRoundAndSubmission.round?.roundEnd
      : spanishNextRound?.roundStart;
  }

  function getTotalSuccessPoints() {
    if (
      location.pathname === `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}`
    ) {
      return englishCurrentRoundAndSubmission?.submission ||
        englishPastRoundsPredictions?.length
        ? (
            (englishCurrentRoundAndSubmission?.submission && [
              englishCurrentRoundAndSubmission,
            ]) ||
            []
          )
            .concat(...(englishPastRoundsPredictions || []))
            ?.map((r) => r?.submission?.successPoints || 0)
            ?.reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || ""
        : null;
    } else if (
      location.pathname === `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}`
    ) {
      return spanishCurrentRoundAndSubmission?.submission ||
        spanishPastRoundPredictions?.length
        ? (
            (spanishCurrentRoundAndSubmission?.submission && [
              spanishCurrentRoundAndSubmission,
            ]) ||
            []
          )
            .concat(...(spanishPastRoundPredictions || []))
            ?.map((r) => r?.submission?.successPoints || 0)
            ?.reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || ""
        : null;
    }
  }

  let nextRoundDateFormatedOnlyMonth = moment(nextRoundTime).format("MMMM");
  let nextRoundDateFormatedOnlyDayMum = moment(nextRoundTime).format("D");
  let nextRoundDateFormatedOnlyDayName = moment(nextRoundTime).format("dddd");

  const checkPath = () => {
    return `/${CURRENT_LEAGUE_ENDPOINT_1}`;
  };

  let summery;
  if (
    (location.pathname === `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}` &&
      spanishPastRoundPredictions) ||
    (location.pathname === `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}` &&
      englishPastRoundsPredictions)
  ) {
    summery = (
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          border: "#F5F5F5 solid 1px",
          borderRadius: "10px",
          padding: "1rem 1rem",
        }}
        className="d-flex"
      >
        <div>
          <span>Tu puntuación</span>
        </div>
        <div>
          <span>{`${getTotalSuccessPoints()} `}</span>
          <span style={{ color: "#A7A7A7" }}>Puntos</span>
        </div>
        <div>
          <button
            onClick={() => {
              history.push(`/leaderboard/${currentLeagueInRoute}`);
            }}
            className="leader-board-btn"
          >
            <p>Clasificación</p>
          </button>
        </div>
      </div>
    );
  }

  const isAndroid = sessionStorage.getItem("os") === "android";

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  return (
    <div className="RSbody">
      {isDesktopOrLaptop && (
        <DesktopHeader resultsScheduleHeader headerText="RESULTADOS" />
      )}

      <div
        style={
          isDesktopOrLaptop
            ? {
                paddingTop: "2em",
                paddingBottom: "5rem",
                fontWeight: 500,
                maxWidth: 1045,
                position: "relative",
              }
            : {
                paddingTop: "11em",
                paddingBottom: "5rem",
                fontWeight: 500,
                maxWidth: 900,
                background: "white",
                position: "relative",
              }
        }
        className="container"
      >
        <div style={{ paddingTop: `env(safe-area-inset-top, 100px)` }}>
          <>
            <div className="m-0 row justify-content-center">
              {banner && (
                <Banner banner={banner} redirectURL={bannerRedirectURL} />
              )}

              <div style={borders} className="col-3"></div>
              <p className="col-6 px-2 text-center">
                Próxima {nextRoundName || "Jornada"}
              </p>
              <div style={borders} className="col-3"></div>
              {showCountDown && (
                <div className="w-100 d-flex justify-content-center ">
                  <CountDown
                    enableClose={false}
                    counterPosition="top"
                    nextRoundName={countDownData.roundName}
                    nextRoundTime={countDownData.roundTime}
                    entriesUntilOrFrom={countDownData.entriesUntilOrFrom}
                  />
                </div>
              )}
            </div>
            {/* if current round is active, make the link go to the same page */}
            {/* <Link to={checkPath()}>
              <div className="container">
                <div className="row">
                  <div style={nextRoundLeftBox} className="col-4 py-2">
                    <p
                      className="text-center mb-0"
                      style={{ color: "#ffffff", fontWeight: 500 }}
                    >
                      {nextRoundDateFormatedOnlyDayName}
                    </p>
                    <p
                      style={{ fontSize: 40, color: "#ffffff" }}
                      className="text-center mb-0"
                    >
                      {nextRoundDateFormatedOnlyDayMum}
                    </p>
                    <p
                      style={{ fontSize: "1.1rem", color: "#ffffff" }}
                      className="text-center mb-0"
                    >
                      {nextRoundDateFormatedOnlyMonth}
                    </p>
                  </div>
                  <div style={backgroungImage} className="col-8">
                    <div style={textDivStyle}>
                      <p style={untillWhenStyle}>
                        {nextRoundName || "Proxima jornada"}
                        <br />
                        Haz tu pronóstico{" "}
                        {isEntriesUntilOrFrom === "from"
                          ? "desde"
                          : "hasta"}{" "}
                        {nextRoundTime.format("dddd")}{" "}
                        {nextRoundTime.format("D")} de{" "}
                        {nextRoundTime.format("MMMM")} a las{" "}
                        {nextRoundTime.format("HH:mm")}
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link> */}
          </>

          {!isNullOrUndefined(getTotalSuccessPoints()) ? (
            <div>
              <div className="container mt-3 mx-0 row">
                <div style={borders} className="col-3"></div>
                <p className="col-6 mb-0 px-0 text-center">Resumen</p>
                <div style={borders} className="col-3"></div>
              </div>
              {summery}
            </div>
          ) : (
            ""
          )}

          {(location.pathname ===
            `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}` &&
            englishCurrentRoundAndSubmission?.submission) ||
          (location.pathname ===
            `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}` &&
            spanishCurrentRoundAndSubmission?.submission) ? (
            <div className="container m-0 mt-3 row">
              <div style={borders} className="col-3"></div>
              <p className="col-6 px-2 text-center">Current Round</p>
              <div style={borders} className="col-3"></div>
            </div>
          ) : null}

          {location.pathname ===
            `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}` &&
          englishCurrentRoundAndSubmission?.submission ? (
            <Link
              style={linkStyle}
              to={`${location.pathname}/${englishCurrentRoundAndSubmission.round.id}`}
              // key={englishCurrentRoundAndSubmission.round.id}
            >
              <PastResults
                round={englishCurrentRoundAndSubmission?.round}
                submission={englishCurrentRoundAndSubmission?.submission}
                key={englishCurrentRoundAndSubmission?.submission?.id}
              ></PastResults>
            </Link>
          ) : location.pathname ===
              `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}` &&
            spanishCurrentRoundAndSubmission?.submission ? (
            <Link
              style={linkStyle}
              to={`${location.pathname}/${spanishCurrentRoundAndSubmission.round.id}`}
              // key={spanishCurrentRoundAndSubmission.round.id}
            >
              <PastResults
                round={spanishCurrentRoundAndSubmission?.round}
                submission={spanishCurrentRoundAndSubmission?.submission}
                key={spanishCurrentRoundAndSubmission?.submission?.id}
              ></PastResults>
            </Link>
          ) : null}

          <div className="container mt-3 mx-0 row">
            <div style={borders} className="col-3"></div>
            <p className="col-6 mb-0 px-0 text-center">Jornadas previas</p>
            <div style={borders} className="col-3"></div>
          </div>

          {englishPastRoundsPredictions <= 0 &&
          location.pathname ===
            `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}` ? (
            <div>
              <p style={{ fontWeight: "bold" }} className="text-center">
                No puedes ver la ronda actual ni las jornadas anteriores, ya que
                aún no realizaste ningún pronóstico
              </p>
            </div>
          ) : null}
          {spanishPastRoundPredictions <= 0 &&
          location.pathname ===
            `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}` ? (
            <div>
              <p style={{ fontWeight: "bold" }} className="text-center">
                No puedes ver la ronda actual ni las jornadas anteriores, ya que
                aún no realizaste ningún pronóstico
              </p>
            </div>
          ) : null}

          {location.pathname ===
          `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}`
            ? englishPastRoundsPredictions?.map((pastPrediction) => {
                return (
                  <Link
                    style={linkStyle}
                    to={`${location.pathname}/${pastPrediction.round.id}`}
                    key={pastPrediction.round.id}
                  >
                    <PastResults
                      round={pastPrediction?.round}
                      submission={pastPrediction?.submission}
                      key={pastPrediction?.submission?.id}
                    ></PastResults>
                  </Link>
                );
              })
            : spanishPastRoundPredictions?.map((pastPrediction) => {
                return (
                  <Link
                    style={linkStyle}
                    to={`${location.pathname}/${pastPrediction.round.id}`}
                    key={pastPrediction.round.id}
                  >
                    <PastResults
                      round={pastPrediction?.round}
                      submission={pastPrediction?.submission}
                      key={pastPrediction?.submission?.id}
                      spanishSubmission
                    ></PastResults>
                  </Link>
                );
              })}
        </div>
        {/* <TermsFooter view="results" /> */}
      </div>
      {!isAndroid && (
        <div
          className="terms__RSbody"
          style={{ bottom: isTablet ? "2.25rem" : "5rem" }}
        >
          <Link to={"/terms-conditions"}>TÉRMINOS Y CONDICIONES</Link>
        </div>
      )}
    </div>
  );
};

export default RSbody;
