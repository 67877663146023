import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BASE_URL } from "../environment";
import { UserContext } from "../contexts/UserContext";
import { CURRENT_CAMPAIGN_ID } from "../leagues/leagues";
import PagesEnum from "../enums/pages";

export const ResultsScheduleContext = createContext();
export function ResultsScheduleProvider({ children }) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isDataOld, setIsDataOld] = useState(true);
  const [apiCallWasMade, setApiCallWasMade] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [banners, setBanners] = useState(null);

  const [englishPastRoundsPredictions, setEnglishPastRoundsPredictions] =
    useState(null);

  const [englishNextRound, setEnglishNextRound] = useState(null);

  const [englishPredictionsForNextRound, setEnglishPredictionsForNextRound] =
    useState(null);

  const [nextEnglishRoundSubmission, setnextEnglishRoundSubmission] =
    useState(null);

  const [spanishPastRoundPredictions, setSpanishPastRoundsPredictions] =
    useState([]);

  const [spanishNextRound, setSpanishNextRound] = useState(null);

  const [spanishPredictionsForNextRound, setSpanishPredictionsForNextRound] =
    useState(null);

  const [nextSpanishRoundSubmission, setnextSpanishRoundSubmission] =
    useState(null);

  const [spanishPredictionsForLastRound, setSpanishPredictionsForLastRound] =
    useState(null);

  const oldDataIntervalRef = useRef();

  useEffect(() => {
    if (oldDataIntervalRef.current) {
      clearInterval(oldDataIntervalRef.current);
    }
    oldDataIntervalRef.current = setInterval(() => {
      setIsDataOld(true);
    }, 60000);
    return () => {
      clearInterval(oldDataIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    let jwt = localStorage.getItem("jwt-token");
    setLoading(true);
  }, [apiCallWasMade, user]);

  useEffect(() => {
    if (!isDataOld) return;
    let jwt = localStorage.getItem("jwt-token");
    setLoading(true);
    axios
      .get(`${BASE_URL}/api/get-rounds?campaignId=${CURRENT_CAMPAIGN_ID}`, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
      })
      .then((res) => {
        setSpanishPastRoundsPredictions(res?.data?.pastRoundsWithPredictions);
        setSpanishNextRound(res?.data?.nextRoundWithPredictions?.round);
        setSpanishPredictionsForNextRound(
          res?.data?.nextRoundWithPredictions?.submission?.matchPredictions
        );
        setSpanishPredictionsForLastRound(
          res?.data?.lastRoundWithPredictions?.submission?.matchPredictions
        );

        setnextSpanishRoundSubmission({
          submission: res?.data?.nextRoundWithPredictions?.submission,
        });
        //in an object to stay truthy, to know that it was set
        setIsDataOld(false);
      })
      .catch(() => {
        setIsDataOld(false);
      });
    setLoading(false);
  }, [apiCallWasMade, user, isDataOld]);

  useEffect(() => {
    let jwt = localStorage.getItem("jwt-token");
    const params = {
      campaignId: CURRENT_CAMPAIGN_ID,
      page: PagesEnum.results,
    };
    axios
      .post(`${BASE_URL}/api/get-banners`, params, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
      })
      .then((res) => {
        console.log({ res });
        setBanners(res?.data?.banners);
      });
  }, []);
  // useEffect(() => {
  //   let jwt = localStorage.getItem("jwt-token");
  //   axios
  //     .get(`${BASE_URL}/api/get-results-banner`, {
  //       headers: {
  //         "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
  //         "jwt-token": jwt,
  //       },
  //     })
  //     .then((res) => {
  //       setBanner(res?.data);
  //     });
  // }, []);

  return (
    <ResultsScheduleContext.Provider
      value={{
        modalActive,
        spanishPastRoundPredictions,
        spanishNextRound,
        englishPastRoundsPredictions,
        englishNextRound,
        englishPredictionsForNextRound,
        spanishPredictionsForNextRound,
        spanishPredictionsForLastRound,
        apiCallWasMade,
        nextEnglishRoundSubmission,
        nextSpanishRoundSubmission,
        setEnglishNextRound,
        setEnglishPredictionsForNextRound,
        setnextEnglishRoundSubmission,
        setSpanishPastRoundsPredictions,
        setSpanishNextRound,
        setSpanishPredictionsForNextRound,
        setnextSpanishRoundSubmission,
        setApiCallWasMade,
        setLoading,
        setEnglishPastRoundsPredictions,
        setModalActive,
        isDataOld,
        setIsDataOld,
        banners,
      }}
    >
      {children}
    </ResultsScheduleContext.Provider>
  );
}
