import React from "react"
import Nav from "../Header/Nav"
import ResultsTabs from "./ResultsTabs"
import MediaQuery from "react-responsive"

function RSheader({ pageName, leagues }) {
  return (
    <div
      style={{ paddingTop: `env(safe-area-inset-top, 50px)` }}
      className={"header-container fixed-top bg-white"}
    >
      <MediaQuery maxWidth={768}>
        <Nav pageName={pageName}></Nav>
        <ResultsTabs tabNames={leagues}></ResultsTabs>
      </MediaQuery>
    </div>
  )
}

export default RSheader
