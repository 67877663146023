import React, { useEffect, useState } from "react"
import smallShirt from "../images/accumulator/accumulatorShirt.svg"
import viCircle from "../images/accumulator/viCircle.svg"
import vi from "../images/accumulator/vi.svg"

function AccumulatorSingleMatchBBTS({
  index,
  homeTeamName,
  awayTeamName,
  markets,
  handleBBTS,
  homeTeamShirt,
  awayTeamShirt,
  updatedFtrArr,
}) {
  //navigating inside the API
  let market = markets.findIndex((market) => {
    return market.marketType === 3
  })

  let possibleOutcomes = markets[market]?.possibleOutcomes

  //where to put v initially
  let outcomeTrue = possibleOutcomes?.find((outcome) => {
    if (updatedFtrArr) {
      return outcome.id === updatedFtrArr
    } else {
      return outcome.isSelected === true
    }
  })

  let outcomeNameTrue = outcomeTrue?.outcomeName

  //

  let BothTeamScoredIndex = possibleOutcomes?.findIndex((outcome) => {
    return outcome.outcomeName === "BothTeamScored"
  })

  let NoBothTeamScored = possibleOutcomes?.findIndex((outcome) => {
    return outcome.outcomeName === "NoBothTeamScored"
  })

  // const yesOdds = markets[market]?.possibleOutcomes[BothTeamScoredIndex].odds
  const yesId = markets[market]?.possibleOutcomes[BothTeamScoredIndex].id

  // const noOdds = markets[market]?.possibleOutcomes[NoBothTeamScored].odds
  const noId = markets[market]?.possibleOutcomes[NoBothTeamScored].id
  //end of navigation inside the API

  //convert from decimal to american odds
  const oddsAmericanConverter = (decimalNum) => {
    if (decimalNum > 2) {
      return (decimalNum = `+${Math.round((decimalNum - 1) * 100)}`)
    } else if (decimalNum < 2) {
      return (decimalNum = Math.round(-100 / (decimalNum - 1)))
    }
  }

  let americanBothTeams = markets[market]?.possibleOutcomes[BothTeamScoredIndex]?.usPrice;
  let americanNoBothTeams = markets[market]?.possibleOutcomes[NoBothTeamScored]?.usPrice;

  const [yesBtn, setYesBtn] = useState(false)
  const [noBtn, setNoBtn] = useState(false)

  useEffect(() => {
    if (outcomeNameTrue === "BothTeamScored") {
      setYesBtn(true)
      handleBBTS(index, yesId, !yesBtn)
    } else if (outcomeNameTrue === "NoBothTeamScored") {
      setNoBtn(true)
      handleBBTS(index, noId, !noBtn)
    }
  }, [])

  function yesBtnToggle() {
    if (!yesBtn){
      setYesBtn(true)
      setNoBtn(false)
      handleBBTS(index, yesId, true)
    } else {
      setYesBtn(false)
      handleBBTS(index, yesId, false)
    }

  }
  function noBtnToggle() {
    if (!noBtn){
      setNoBtn(true)
      setYesBtn(false)
      handleBBTS(index, noId, true)
    } else {
      setNoBtn(false)
      handleBBTS(index, noId, false)
    }

  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-5 d-flex px-0 pt-2">
          <img className="small-shirt" src={homeTeamShirt} alt="small shirt" />
          <p className="mb-2  pl-2">{homeTeamName}</p>
        </div>
        <button onClick={yesBtnToggle} className="col text-center btn-style">
          {yesBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
              {+americanBothTeams > 0 && '+'}{americanBothTeams}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
        <button onClick={noBtnToggle} className="col text-center btn-style">
          {noBtn ? (
            <div className="vi-box">
              <img className="viCircle" src={viCircle} alt="" />
              <img className="vi" src={vi} alt="" />
            </div>
          ) : (
            <div className="accum-matches-box-numbers">
            {+americanNoBothTeams > 0 && '+'}{americanNoBothTeams}{" "}
              <div className="vi-box">
                <img
                  className="viCircle"
                  src={viCircle}
                  alt=""
                  style={{ display: "none" }}
                />
                <img
                  className="vi"
                  src={vi}
                  alt=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </button>
      </div>
      <div className="row border-bottom">
        <div className="col-5 d-flex px-0 pb-2">
          <img className="small-shirt" src={awayTeamShirt} alt="small shirt" />
          <p className="m-0 px-2">{awayTeamName}</p>
        </div>
      </div>
    </div>
  )
}

export default AccumulatorSingleMatchBBTS
