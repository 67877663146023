import React from "react";
import Header from "../Header/Header";
import "./TermsConditions.css";

const TermsConditions = () => {
  return (
    <div className="terms">
      <Header TermsConditions />
      <div style={{ padding: "9em 1em 5em 1em" }}>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5em",
            textTransform: "uppercase",
          }}
          //       style="text-transform: uppercase !important;
          //   text-align: center;font-weight:bold font-size: 1.5em;"
        >
          TÉRMINOS Y CONDICIONES: MVP MX
        </p>
        <p>
          1. Esta es una promoción de CODERE LIBROS FORANEOS SA DE CV solo
          válida para usuarios residentes en México exclusivamente mayores de 18
          años, que estén registrados como usuarios en codere.mx y que hayan
          verificado su cuenta.
        </p>
        <p>
          2. La duración de la promoción será desde el 13 de diciembre de 2021
          en adelante.
        </p>
        <p>3. Podrán participar en la presente promoción:</p>
        <ul>
          <li>
            Todas aquellas personas que sean mayores de 18 años y estén
            registradas en codere.mx previa verificación de su cuenta.
          </li>
          <li>
            No podrán participar los menores de 18 años, ni los que se
            encuentren inscritos en el registro de interdicción de acceso al
            juego, ni los autoexcluidos, ni aquellos clientes que hayan sido
            catalogados como jugadores con un comportamiento de riesgo.
          </li>
          <li>
            Hacer la predicción de número de goles marcados por cada equipo
            (marcador exacto) de los partidos propuestos por CODERE.
          </li>
        </ul>
        <p>4. Solo se permite una participación por usuario en cada ronda.</p>
        <p>
          5. Se podrán hacer y modificar las predicciones hasta el comienzo del
          primer partido de la ronda. Una vez iniciado el primer evento no se
          admitirán más predicciones.
        </p>
        <p>
          6. Se tendrán en cuenta los goles marcados en los 90 minutos de juego
          y no los marcados en la prórroga.
        </p>
        <p>7. Dependiendo del número de aciertos los premios serán:</p>
        <p>
          <b>Repechaje</b>
        </p>
        <div className="table container border">
          <div className="d-flex">
            <div className="border p-2 col-6">3 aciertos</div>
            <div className="border p-2 col-6">$400 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 aciertos</div>
            <div className="border p-2 col-6">Playera futbol</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">5 aciertos</div>
            <div className="border p-2 col-6">XBOX</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">6 aciertos</div>
            <div className="border p-2 col-6">Experiencia VIP</div>
          </div>
        </div>
        {/* <p>
          <b>Cuartos de Final (ida y vuelta)</b>
        </p>
        <div className="table container border">
          <div className="d-flex">
            <div className="border p-2 col-6">3 aciertos</div>
            <div className="border p-2 col-6">$400 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 aciertos</div>
            <div className="border p-2 col-6">Playera futbol</div>
          </div>
        </div>
        <p>
          <b>Semifinal (ida y vuelta)</b>
        </p>
        <div className="table container border">
          <div className="d-flex">
            <div className="border p-2 col-6">3 aciertos</div>
            <div className="border p-2 col-6">$400 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 aciertos</div>
            <div className="border p-2 col-6">Playera futbol</div>
          </div>
        </div>
        <p>
          <b>Final (ida y vuelta)</b>
        </p>
        <div className="table container border">
          <div className="d-flex">
            <div className="border p-2 col-6">2 aciertos</div>
            <div className="border p-2 col-6">$500 en freebet</div>
          </div>
        </div> */}
        <p>
          Además, los usuarios que participen en la promoción de forma
          consecutiva varias semanas en las que haya partidos, recibirán:
        </p>

        <div className="table container border">
          <div className="d-flex">
            <div className="border p-2 col-6">2 semanas seguidas</div>
            <div className="border p-2 col-6">$40 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">3 semanas seguidas</div>
            <div className="border p-2 col-6">$40 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">4 semanas seguidas</div>
            <div className="border p-2 col-6">$80 en freebet</div>
          </div>
          <div className="d-flex">
            <div className="border p-2 col-6">5 semanas seguidas</div>
            <div className="border p-2 col-6">$100 en freebet</div>
          </div>
        </div>

        {/* <div>
          <table>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <p>
          Estas freebets se entregarán al finalizar la jornada en un plazo
          máximo de 3 días laborables.
        </p>
        <p>
          8. Todos los usuarios con 6 aciertos, ganarán la Experiencia VIP,
          salvo que por causas ajenas a Codere no puedan entregarse. En tal
          caso, se otorgará la Experiencia VIP por orden de participación y el
          resto obtendrá como premio su valor en metálico.
        </p>
        <p>
          9. Si uno de los 6 partidos se pospone, cancela o no concluye, el
          primer premio (Experiencia Vip) se declarará desierto. En el caso de
          que dos de los 6 partidos se pospongan, cancelen o no concluyan, el
          primer premio (Experiencia Vip,) y el segundo premio (Xbox) quedarán
          desiertos y así sucesivamente.
        </p>
        <p>
          10. El ganador de la Experiencia VIP recibirá el premio para dos
          personas mayores de edad que consistirá en el acceso al túnel de
          vestuarios de Club Fútbol Monterrey Rayados, estar al lado de la
          cancha durante el calentamiento y después ver el partido en el Palco.
          Tendrán acceso como si fueran parte del staff de redes sociales del
          club. El único lugar al cual no tienen acceso garantizado es a los
          vestidores. Además de una playera y un balón firmados. El Ganador
          aceptará el premio tal como se ofrece, y no incluye ningún otro
          concepto que no esté especificado en estas Bases. El premio es
          personal e intransferible, no pudiendo cederse a terceras personas.
        </p>
        <p>
          11. Restricciones de participación: No podrán participar los empleados
          y familiares directos del Organizador o de sus empresas asociadas, los
          asesores profesionales y las agencias de publicidad y de promoción que
          tengan relación con el Organizador. No podrán participar los menores
          de 18 años, los que no estén registrados como usuarios en codere.mx
          y/o no hayan verificado su cuenta, ni los que se encuentren inscritos
          en el registro de interdicción de acceso al juego, ni los
          autoexcluidos, ni aquellos clientes que hayan sido catalogados como
          jugadores con un comportamiento de riesgo.
        </p>
        <p>
          12. Los ganadores aceptarán el premio tal como se ofrece, y no incluye
          ningún otro concepto que no esté especificado en estos Términos y
          Condiciones.
        </p>
        <p>
          13. El ganador o ganadores deberán estar registrados en codere.mx y
          haber realizado un depósito antes del comienzo de la promoción para
          poder recibir la freebet.
        </p>
        <p>
          14. Los clientes de Codere que participen en la promoción aceptan los
          términos y condiciones y reconocen que el incumplimiento de las mismas
          puede provocar su descalificación.
        </p>
        <p>
          15. Las freebets estas deberán ser utilizadas en base a los términos y
          condiciones de las freebets de codere.mx:
        </p>
        <ul>
          <li>
            Las freebets/apuestas gratis deberán ser utilizadas con base en los
            términos y condiciones de las freebets/apuestas gratis de codere.mx:
          </li>
          <li>
            Las freebets deberán utilizarse para realizar apuestas con un momio
            o coeficiente igual o superior a -200
          </li>
          <li>
            Las freebets tendrán una duración de 7 días desde su obtención.
            Transcurrido dicho periodo, las freebets no utilizadas serán
            eliminadas.
          </li>
          <li>
            Al usar las freebets, en caso de que la apuesta sea ganadora, el
            usuario solo recibirá la ganancia neta derivada de las mismas.
          </li>
          <li>
            Al usar las freebets, en caso de que la apuesta resulte cancelada no
            se devolverá el importe de la freebet ni se asignará otra en su
            lugar.
          </li>
        </ul>
        <p>
          16. El organizador no tendrá obligación de entregar el premio en los
          supuestos siguientes:
        </p>
        <ul>
          <li>
            Si hay sospecha de incumplimiento de los presentes términos y
            condiciones o de las condiciones generales de contratación aceptadas
            por el cliente, en particular en lo relativo a la veracidad de datos
            y a la prohibición de registrar más de un usuario por persona
            física, sin perjuicio de las acciones adicionales que procedieran
            por tal incumplimiento.
          </li>
          <li>
            Si hay sospecha de que un cliente ha utilizado diferentes recursos
            para desvirtuar el objeto de la promoción.
          </li>
          <li>
            Si el cliente no aceptara recibir comunicaciones publicitarias por
            correo electrónico, sms u otro medio de comunicación equivalente.
          </li>
          <li>
            Todas las ofertas se limitan a una por persona, dirección de
            domicilio, dirección de correo electrónico, número de teléfono, el
            mismo número de cuenta del método de pago (ej. tarjeta de crédito o
            débito, cuenta de Paypal, etc.), o cualquier circunstancia en la que
            un ordenador sea susceptible a ser compartido (ej. en una biblioteca
            pública, un lugar de trabajo, etc.).
          </li>
        </ul>
        <p>
          17. Codere se reserva el derecho de modificar, alterar, suspender o
          finalizar esta promoción en cualquier momento.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
