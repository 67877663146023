import "./App.css";
import TermsFooter from "./GeneralComponents/TermsFooter";
import "./btn.css";
import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "./leagues/leagues";
import Welcome from "./screens/welcome";
import HowToPlay from "./howToPlay/HowToPlay";
import Footer from "./Footer";
import TermsConditions from "./TermsConditions/TermsConditions";
import EnglishLeague from "./leagues/EnglishLeague";
import SpanishLeague from "./leagues/SpanishLeague";
import ForgotPassword from "./Forms/ForgotPassword";
import RenewPassword from "./Forms/RenewPassword";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import RSheader from "./ResultsSchedule/RSheader";
import RSbody from "./ResultsSchedule/RSbody";
import Account from "./Account";
import SideNav from "./SideNav";
import ResultDetails from "./ResultsSchedule/ResultDetails";
import AccumulatorMatches from "./Accumulator/AccumulatorMatches";
import Leaderboard from "./leaderboard/leaderboard";
import SideNavDesktop from "./SideNavDesktop";
import MediaQuery from "react-responsive";
import { PredictionsContext } from "./contexts/PredictionsContext";
import { SideNavContext } from "./contexts/SideNavContext";
import { Platforms } from "./components/Share/PlatfromsUrls";
import { UserContext } from "./contexts/UserContext";
import { ResultsScheduleContext } from "./contexts/ResultsScheduleContext";
import { Spinner } from "react-bootstrap";
import localforage from "localforage";
axios.defaults.headers.common["app-api-key"] =
  "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed";

const App = () => {
  const history = useHistory();
  const footerRef = useRef();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [redirectUserPath, setRedirectUserPath] = useState(null);

  const { loading, leaderboardLoading } = useContext(PredictionsContext);
  const { user } = useContext(UserContext);

  const { spanishCurrentRoundAndSubmission, spanishLastRoundAndSubmission } =
    useContext(PredictionsContext);
  const { nextSpanishRoundSubmission, spanishPastRoundPredictions } =
    useContext(ResultsScheduleContext);

  const { sideBar, setSidebar } = useContext(SideNavContext);

  /*
   * This functions controls the showing welcome page logic by
   * checking if user is true AND he have predictions from server
   * or from local forage
   */
  const displayWelcome = useCallback(async () => {
    if (
      !(
        user &&
        (Boolean(
          spanishCurrentRoundAndSubmission?.submission ||
            spanishLastRoundAndSubmission?.submission ||
            nextSpanishRoundSubmission?.submission ||
            spanishPastRoundPredictions?.length > 0
        ) ||
          (await localforage.getItem("predictionArray")))
      )
    ) {
      setRedirectUserPath("/welcome");
      return;
    }
    if (nextSpanishRoundSubmission) {
      if (nextSpanishRoundSubmission?.submission) {
        setRedirectUserPath(`/${CURRENT_LEAGUE_ENDPOINT_1}/1x2`);
        return;
      } else {
        setRedirectUserPath(`/${CURRENT_LEAGUE_ENDPOINT_1}`);
        return;
      }
    } else if (spanishCurrentRoundAndSubmission) {
      setRedirectUserPath(`/${CURRENT_LEAGUE_ENDPOINT_1}`);
      return;
    }
  }, [
    nextSpanishRoundSubmission,
    spanishCurrentRoundAndSubmission,
    spanishLastRoundAndSubmission?.submission,
    spanishPastRoundPredictions?.length,
    user,
  ]);

  useEffect(() => {
    displayWelcome();
  }, [displayWelcome]);

  useEffect(() => {
    setSidebar(false);
  }, [location, setSidebar]);

  useEffect(() => {
    setIsLoading(true);
    displayWelcome();
    setIsLoading(false);
  }, [displayWelcome]);

  return (
    <div id="" className="App">
      <MediaQuery minWidth={768}>
        <SideNavDesktop platforms={Platforms} />
      </MediaQuery>
      <SideNav platforms={Platforms} />

      <Switch>
        <Route exact path={"/"}>
          <Redirect
            to={redirectUserPath}
            // to={redirectUserPath ? `/welcome` : `/${CURRENT_LEAGUE_ENDPOINT_1}`}
          />
        </Route>
        <Route exact path={"/how-to-play"}>
          <HowToPlay />
        </Route>
        <Route exact path={"/terms-conditions"}>
          <TermsConditions />
        </Route>
        <Route exact path={"/welcome"}>
          {!user && redirectUserPath === "/welcome" ? (
            <Welcome />
          ) : (
            <Redirect to={redirectUserPath} />
          )}
        </Route>
        <Route exact path={"/forgot-password"}>
          <ForgotPassword />
        </Route>
        <Route path={"/renew-password"}>
          <RenewPassword />
        </Route>

        <Route exact path={"/account"}>
          <Account />
        </Route>

        <Route exact path={"/results"}>
          <RSbody></RSbody>
        </Route>

        <Route exact path={`/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}`}>
          <MediaQuery maxWidth={768}>
            <RSheader pageName="rs" />
          </MediaQuery>
          <RSbody></RSbody>
        </Route>

        <Route exact path={`/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}`}>
          <RSheader pageName="rs" />
          <RSbody></RSbody>
        </Route>

        <Route exact path={`/leaderboard/${CURRENT_LEAGUE_ENDPOINT_2}`}>
          <Leaderboard />
        </Route>

        <Route exact path={`/leaderboard/${CURRENT_LEAGUE_ENDPOINT_1}`}>
          <Leaderboard />
        </Route>

        <Route path={`/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}/:roundId`}>
          <ResultDetails />
        </Route>

        <Route path={`/results-schedule/${CURRENT_LEAGUE_ENDPOINT_1}/:roundId`}>
          <ResultDetails />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_1}/1x2`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_1}`}
            accumPage={"ftResult"}
            from={"accumulator"}
            typeOfBet={"FTR"}
          />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_2}/1x2`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_2}`}
            accumPage={"ftResult"}
            from={"accumulator"}
            typeOfBet={"FTR"}
          />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_1}/u-o-2.5-goals`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_1}`}
            accumPage={"uo"}
            from={"accumulator"}
            typeOfBet={"OU"}
          />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_2}/u-o-2.5-goals`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_2}`}
            accumPage={"uo"}
            from={"accumulator"}
            typeOfBet={"OU"}
          />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_1}/bbts`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_1}`}
            accumPage={"bbts"}
            from={"accumulator"}
            typeOfBet={"BBTS"}
          />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_2}/bbts`}>
          <AccumulatorMatches
            league={`${CURRENT_LEAGUE_ENDPOINT_2}`}
            accumPage={"bbts"}
            from={"accumulator"}
            typeOfBet={"BBTS"}
          />
        </Route>

        <Route path={`/${CURRENT_LEAGUE_ENDPOINT_2}`}>
          <EnglishLeague pageName={"PREDICTIONS"} from={"predictions"} />
        </Route>

        <Route exact path={`/${CURRENT_LEAGUE_ENDPOINT_1}`}>
          <SpanishLeague
            footerHeight={footerRef?.current?.clientHeight}
            pageName={"PREDICTIONS"}
            from={"predictions"}
          />
        </Route>

        <Route exact path={["/"]} />
      </Switch>
      {location.pathname === "/welcome" ? null : (
        <MediaQuery maxWidth={768}>
          {sessionStorage.getItem("os") === "android" && (
            <TermsFooter
              leaderboardSpinner={leaderboardLoading}
              predictionsSpinner={loading}
            />
          )}
          <Footer forwardedRef={footerRef} />
        </MediaQuery>
      )}
    </div>
  );
};

export default App;
